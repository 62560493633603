import React from "react";
import { useTranslation } from "react-i18next";
import { AboutGrandTagStyle } from "./style";

const man = require("@/assets/img/Vision.png");
const photo = require("@/assets/img/Connect_photo@2x.jpg");
const bgc1 = require("../../../assets/img/bg-01.png");
export default function AboutGrandTag() {
  const { t } = useTranslation();

  return (
    <AboutGrandTagStyle className="wrap-v1">
      <div className="container ">
        <div className="content">
          <div className="title fontstyle_SB" >
            {t("contect_faq.about_grandtag")}
          </div>
          <div className="desc">
            <p>{t("contect_faq.desc1")}</p>
          </div>
        </div>
        <div className="gt_position_title">
          <img src={bgc1.default} alt="" />
          <div className="gt_mobie_title fontstyle_SB">
            {t("contect_faq.about_grandtag")}
          </div>
        </div>

      </div>
      <div className="man-bgc">
        <img src={man.default} alt="" />
      </div>
    </AboutGrandTagStyle>
  );
}
