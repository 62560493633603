/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Drawer } from "antd";
import TopBanner from "./TopBanner";
import styled from "styled-components";
import I18n from "@/I18n";
import _ from "lodash";

import { routes_info, routes_media_info } from "@/mocks/leter_data";

const logo = require("@/assets/img/GTHeader_logo@2x.png");
const header_tabbar_icon = require("../../assets/img/Header_tabber_icon@2x.png");
const header_icon_clone = require("../../assets/img/header_icon_clone@2x.png");

// 遍历路由链接地址
const ShowSelectItem = ({ item }) => {
  const { t } = useTranslation();
  return (
    <NavLink to={item.link} exact className="link">
      {t(item.title)}
    </NavLink>
  );
};

function GT_Header() {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [active, setActive] = useState(1);
  const elRef = useRef();
  const headerRef = useRef();
  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
      elRef.current.src = header_icon_clone.default;
      elRef.current.className = "header_tabber_icon";
    } else {
      setVisible(false);
      elRef.current.src = header_tabbar_icon.default;
      elRef.current.className = "header_icon_clone";
    }
  };

  const onClose = () => {
    setVisible(false);
    elRef.current.src = header_tabbar_icon.default;
    elRef.current.className = "header_icon_clone";
  };

  const languageArr = [
    {
      type: "简体",
      language: "zh-CN",
    },
    {
      type: "繁体",
      language: "zh-TW",
    },
    {
      type: "English",
      language: "en",
    },
  ];
  const aStyle = {
    display: "block",
    color: "#333333",
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getIndex = () => {
    return _.findIndex(languageArr, (o) => o.language === I18n.language);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setActive(getIndex());
  }, [getIndex, headerRef]);

  const onClick = (key) => {
    if (key === "en") {
      localStorage.setItem("language", "en");
    } else if (key === "zh-TW") {
      localStorage.setItem("language", "zh-TW");
    } else {
      localStorage.setItem("language", "zh-CN");
    }
    I18n.changeLanguage(key);
  };

  return (
    <HeaderStyle>
      <TopBanner />
      <div className="wrap-v1 header" ref={headerRef}>
        <HeaderLeft>
          <img src={logo.default} alt="" />
        </HeaderLeft>

        <HeaderRight>
          {routes_info.map((item) => {
            return (
              <div key={item.title} className="select-item">
                <ShowSelectItem item={item} />
              </div>
            );
          })}

          <img
            src={header_tabbar_icon.default}
            alt=""
            className="header_tabber_icon"
            ref={elRef}
            onClick={(e) => {
              showDrawer();
            }}
          />
        </HeaderRight>

        <Drawer
          placement={"left"}
          closable={false}
          onClose={onClose}
          visible={visible}
          maskClosable={true}
          className="drawerclass"
          getContainer={false}
        >
          {routes_media_info.map((item) => {
            return (
              <div key={item.title} className="mobie-select-item">
                <a href={item.link} className="link">
                  {t(item.title)}
                </a>
              </div>
            );
          })}
          <div className="change-language">
            <div className="title">{t("routes-media.choose_a_language")}</div>
            <div className="button-group">
              {languageArr.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={
                      active === index ? "gt_header_index_lang_active" : ""
                    }
                    onClick={(e) => {
                      onClick(item.language);
                    }}
                  >
                    <a href="" target="_self" style={aStyle}>
                      {item.type}
                    </a>
                  </button>
                );
              })}
            </div>
          </div>
        </Drawer>
      </div>
    </HeaderStyle>
  );
}

export const HeaderStyle = styled.div`
  font-size: 18px;
  position: fixed;
  width: 100vw;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  .header {
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    padding: 0 4%;
    border-bottom: 12px solid #f5f7fb;
  }
`;

export const HeaderLeft = styled.div`
  img {
    height: 50.94px;
    /* width: 181.84px; */
    line-height: 90px;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  order: 3;
  .select-item {
    .link {
      padding: 15px 0;
      color: #000000;
    }
  }

  .active {
    padding: 0 5px 10px 5px;
    position: relative;
    font-family: SossBo;
    &:after {
      content: "";
      position: absolute;
      border-bottom: 3px #f33500 solid;
      bottom: 1%;
      left: 50%;
      transform: translate(-50%);
      width: 60px;
      z-index: -1;
    }
  }

  .header_tabber_icon {
    display: none;
  }
  .header_icon_clone {
    display: none;
  }
	@media (min-width:800px) and (max-width: 1400px) {
	  .select-item {
			.link {
				font-size:12px;
			}
		}
	}
	
  @media (max-width: 1000px) {
    width: auto;

    .select-item {
      display: none;
    }
    /* xxx */
    .header_tabber_icon {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 3%;
    }

    /* 菜单 */
    .header_icon_clone {
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    order: 3;
  }
`;

export default memo(GT_Header);
