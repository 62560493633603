import React, { useEffect, useState } from "react";
import styled from "styled-components";

import VideoFrame from "../GTVideoDetail/videoFrame";
import { useTranslation } from "react-i18next";
// import { getVideo } from "@/service/video";
// import { ImageURL } from "@/service/config";
// const play = require("../../assets/img/video-detail-play@2x.png");

// function GTVideoDetailItem(props) {
//   const { info, changeVideo } = props;
//   const Image = function (pic) {
//     return ImageURL + pic;
//   };
//   return (
//     <GTVideoDetailItemWrapper >
//       <div className="container">
//         <div className="play-page">
//           <div className="play-bgc">
//             <img src={Image(info.pic)} alt="" />
//           </div>
//           <div className="play-icon">
//             <img src={play.default} alt="" />
//           </div>
//           <div className="time">18:20</div>
//         </div>
//         <div className="play-title">
//           {info.title}
//         </div>
//       </div>
//     </GTVideoDetailItemWrapper>
//   );
// }

export default function GTVideoDetail(props) {
  const { t } = useTranslation();

  // 传入单个视频数据
  const { info } = props.location.state;
  // const [videodata, setVideoData] = useState([])
  const [videourl, setVideoUrl] = useState({
    title: t("title"),
    link: "https://www.youtube.com/embed/ZoEcjD8tEuE",
  });

  const titleStyle = {
    fontSize: "16px",
    fontFamily:'SossBo',
    lineHeight: "24px",
    margin: "2% 4%",
  };

  useEffect(() => {
    if (info) {
      setVideoUrl(info);
    }
  }, []);

  return (
    <GTVideoDetailWrapper className="fix-margin-top">
      <VideoFrame videourls={videourl} />
      <div className="title" style={titleStyle}>
        {videourl.title}
      </div>

    </GTVideoDetailWrapper>
  );
}

const GTVideoDetailWrapper = styled.div`
  .container {
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5% 0;
      margin: 0 3%;
      .title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }

      .more {
        a {
          font-size: 12px;
          line-height: 16px;
          color: black;
        }
      }
    }

    .item {
      display: flex;
      overflow-x: scroll;
    }
  }
`;
