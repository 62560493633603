import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getDict } from "@/service/video";
// const icon = require("../../assets/img/Todo_icon@2x.png");
const TodoStyle = styled.div`
  width: 128px;
  height: 128px;
  background-color: #f33500;
  border-radius: 30px;
  padding: 15px 0 10px 0;
  cursor: pointer;
  position: fixed;
  right: 25px;
  top: calc(50% - 64px);
  cursor: pointer;
  z-index: 1;
  animation: shadow 2s infinite alternate;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      width: ${window.localStorage.getItem("i18nextLng") === "en"
        ? "18px"
        : "30px"};
      height: ${window.localStorage.getItem("i18nextLng") === "en"
        ? "18px"
        : "30px"};
    }

    .title {
      line-height: 22px;
      color: #fff;
      text-align: center;
      padding: 5px;
      font-size: ${window.localStorage.getItem("i18nextLng") === "en"
        ? "12px"
        : "18px"};
    }
  }
  @keyframes shadow {
    form {
      box-shadow: 0px 0px 0px 2px #f3350060, 0px 0px 0px 4px #f3350040,
        0px 0px 0px 4px #f3350020;
    }

    to {
      box-shadow: 0px 0px 0px 6px #f3350060, 0px 0px 0px 12px #f3350040,
        0px 0px 0px 20px #f3350020;
    }
  }

  @media (min-width: 800px) and (max-width: 1400px) {
    width: 110px;
    height: 110px;
    .content {
      .icon {
        width: 40px;
        height: 40px;
      }
      .title {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 768px) {
    width: ${window.localStorage.getItem("i18nextLng") === "en"
      ? "100px"
      : "84px"};
    height: ${window.localStorage.getItem("i18nextLng") === "en"
      ? "100px"
      : "84px"};
    margin-right: 3%;
    border-bottom-right-radius: 0px;
    border-radius: 15px;
    padding: 0;
    top: auto;
    bottom: 15%;
    right: 0;
    .content {
      .icon {
        width: 36.77px;
        height: 37.9px;
        display: none;
      }
      .title {
        font-size: 12px;
      }
    }
  }
`;

export default function GTTodo(props) {
  const { t } = useTranslation();
  const [btnLink, setBtnLink] = useState();
  useEffect(() => {
    getDict("LINK_CONFIGURATION").then((res) => {
      setBtnLink(res.data?.[0]?.vals);
      console.log(res.data?.[0]?.vals);
    });
  }, []);
  return (
    <TodoStyle>
      <a href={btnLink} target="_blank" rel="noreferrer">
        <div className="content">
          {/* <img src={icon.default} alt="" className="icon" /> */}
          <div className="title">
            {t("todo.reservation")}
            {window.localStorage.getItem("i18nextLng") === "en" ? null : <br />}

            {t("todo.reservation2")}
            {window.localStorage.getItem("i18nextLng") === "en" ? null : <br />}
            {t("todo.reservation3")}
          </div>
        </div>
      </a>
    </TodoStyle>
  );
}
