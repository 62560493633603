import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import HomeHeader from "./HomeHeader";
import HomeTestimonials from "./HomeTestimonials";
import HomeGrandtag from "./HomeGrandtag";
import SpecialtiesVideo from "../../pages/GT_Specialtiesvideo";
import GTTitle from "../../pages/GT_Title";
import GTFAQ from "../../pages/GT_FAQ";
import GTTodo from "../../pages/GT_Todo";
import HomeLectures from "./HomeLectures";
import GTButton from "../../pages/GT_Button";
import HomeReward from "./HomeReward";
import GTModalVideo from "@/pages/GT_ModalVideo";
import { home_testimonals_info } from "../../mocks/leter_data";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";

const video_play = require("../../assets/img/Video-play@2x.png");
const rightbgc = require("@/assets/img/audio1.jpg");
const video_white_play = require("../../assets/img/Video_white_play@2x.png");
const audioSrc = require("../../assets/img/Recording 1_Max Ou.mp3");
const audioSrc2 = require("../../assets/img/Recording_2_May Yin.mp3");
const audioSrc3 = require("../../assets/img/Recording 3_Ted Ip.mp3");
const audioSrc4 = require("../../assets/img/Recording 4_Ada.mp3");
const audioSrc5 = require("../../assets/img/Recordings 5_Samuel Wu.mp3");

const supporting = require("@/assets/img/Home_supportingbgc@2x.jpg");
const detale_line = require("@/assets/img/Detale_line@2x.png");
const dropdown = require("../../assets/img/Home_Test_DropDown@2x.png");
const play_icon = require("../../assets/img/Home_whyGT_icon@2x.png");
const advisor = require("../../assets/img/rcjj.jfif");
const play = require("../../assets/img/Home_whyGT_play@2x.png");
const close = require("@/assets/img/Modal_video_close@2x.png");
const top = require("@/assets/img/Title_top@2x.png");
const left = require("@/assets/img/Title_left@2x.png");
const audioSrc6 = require("../../assets/img/Recordings 5_Samuel Wu.mp3");

const verticalpoint = require("../../assets/img/Home_Specialist_vers.png");
const honrpoint = require("../../assets/img/Home_Specialist_hous@2x.png");

const HomeWrapper = styled.div`
  background-color: #f7f9fc;
  .spe-container {
    position: relative;
    padding: 2% 0;
    padding-top: 160px;
    .specias-button {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1% 0;
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2% 0;
    }
    .trans {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 628px;
      height: 144px;
      z-index: 1;
    }
    .vers {
      position: absolute;
      width: 168px;
      height: 259px;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .spe-container {
      .specias-button {
        position: relative;
        z-index: 2;
      }
    }
  }
  @media (max-width: 768px) {
    .spe-container {
      padding: 0;
      .specias-button {
        display: none;
      }
      .button {
        padding: 0 4%;
      }
      .trans {
        display: none;
      }

      .vers {
        display: none;
      }
    }
  }
`;
const TestStyle = styled.div`
  background-color: #fff;
  padding: 50px 0;
  .test-container {
    display: flex;
    justify-content: space-between;
    padding: 61px 0;
    .content {
      color: black;
      .title {
        font-size: 36px;
        line-height: 48px;
      }
      .desc {
        font-size: 16px;
        line-height: 28px;
        opacity: 0.6;
        margin: 30px 0;
        max-width: 75%;
      }
      .clock {
        padding-top: 100px;
        display: flex;
        .list {
          margin-right: 2%;
          position: relative;
          cursor: pointer;
          .video {
            width: 125px;
            height: 70px;
          }
          .video-play {
            position: absolute;
            width: 23px;
            height: 22px;
            top: 37%;
            left: 38%;
            transform: rotate(360deg);
          }
          .cur {
            animation: rotation 2s linear infinite;
          }
        }
      }
    }
    .rightbgc {
      cursor: pointer;
      position: relative;
      top: -90px;
      right: 64px;
      .right-bgc {
        width: 661px;
        height: 100%;
      }
      .play-bgc {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 74px;
        height: 74px;
        margin-left: -37px;
        margin-top: -37px;
        transform: rotate(360deg);
      }
      .cur {
        animation: rotation 2s linear infinite;
      }
    }
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .test-container {
      width: 100%;
      .content {
        width: 50%;
        .clock {
          padding-top: 40px;
        }
      }
      .rightbgc {
        width: 50%;
        right: 0;
        .right-bgc {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    .test-container {
      flex-direction: column;
      padding: 5% 4%;
      .content {
        margin: 0;
        .title {
          font-size: 18px;
          line-height: 24px;
        }
        .desc {
          color: #333333;
          max-width: 95%;
          opacity: 1;
          margin: 4% 0 4% 0;
        }
        .clock {
          overflow: scroll;
          padding: 3% 0;
          .list {
            padding: 5% 0;
            img {
              width: 100px;
              height: 56px;
            }
          }
        }
      }
      .rightbgc {
        top: 0;
        right: 0;
        .right-bgc {
          width: 100%;
          height: auto;
        }
        .play-bgc {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 43px;
          height: 43px;
          margin-left: -26.5px;
          margin-top: -26.5px;
        }
      }
    }
  }
`;
function GT_Home(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [rotation, setRotation] = useState(false);
  const [rotation2, setRotation2] = useState("");
  const [rotation3, setRotation3] = useState(false);
  const audioList = document.getElementsByClassName("audioList");
  const audioA = document.getElementById("audioA");
  const audioB = document.getElementById("audioB");
  const audioAt = useRef();
  const audioList1 = useRef();
  const audioList2 = useRef();
  const audioList3 = useRef();
  const audioList4 = useRef();
  const audioList5 = useRef();
  const audioList6 = useRef();
  const pRef = useRef();

  const handleOk = () => {
    toggleVideo("hide");
    setShow(false);
  };

  const handleCancel = () => {
    toggleVideo("hide");
    setShow(false);
  };
  function toggleVideo(state) {
    let divs = document.getElementById("popupVidGrandtag");
  }
  const stopFn = (e) => {
    console.log(e);
    if (e === 1) {
      setRotation2("");
    } else if (e === 2) {
      setRotation(false);
    } else {
      setRotation3(false);
    }
  };
  return (
    <HomeWrapper className="fix-margin-top">
      <HomeHeader {...props} />
      <div className="spe-container">
        {
          <GTTitle
            title="home.speciale-title"
            position="right"
            mobieTitle="home.speciale-title"
          />
        }
        <SpecialtiesVideo title="" />
        <div className="specias-button">
          <GTButton
            changeLink={(e) => {
              props.history.push("/specialties");
            }}
            mobieTitle="routes-media.watch"
            poscenter={"center"}
          />
        </div>
        <img src={honrpoint.default} alt="" className="trans" />
        <img src={verticalpoint.default} alt="" className="vers" />
      </div>
      {/* <HomeTestimonials {...props} /> */}
      <TestStyle>
        <GTModalVideo show={show} setShow={setShow} />
        <div className="wrap-v1">
          <GTTitle
            title={t("home.test_props_title")}
            position="left"
            mobieTitle="home.test_props_title"
          />
          <div className="test-container">
            <div className="content">
              {
                // <div className="title fontstyle_SB">{t("home.test_title1")}</div>
              }
              <div className="desc">{t("home.test_desc1")}</div>
              <GTButton
                title={t("home.join_props_title")}
                changeLink={(e) => {
                  props.history.push("/testimonials");
                }}
                styled={{ margin: "5% 0" }}
                mobieTitle="routes-media.watch"
              />
              <div className="clock">
                {home_testimonals_info.map((item, index) => {
                  return (
                    <div
                      className="list"
                      key={index}
                      onClick={(e) => {
                        audioAt.current.pause();
                        setRotation(false);
                        setRotation3(false);
                        if (index === 0) {
                          if (audioList1.current.paused) {
                            audioList1.current.play();
                            setRotation2(index);
                          } else {
                            audioList1.current.pause();
                            setRotation2("");
                          }
                          audioList2.current.pause();
                          audioList3.current.pause();
                          audioList4.current.pause();
                          audioList5.current.pause();
                          audioList6.current.pause();
                        }
                        if (index === 1) {
                          if (audioList2.current.paused) {
                            audioList2.current.play();
                            setRotation2(index);
                          } else {
                            audioList2.current.pause();
                            setRotation2("");
                          }
                          audioList1.current.pause();
                          audioList3.current.pause();
                          audioList4.current.pause();
                          audioList5.current.pause();
                          audioList6.current.pause();
                        }
                        if (index === 2) {
                          if (audioList3.current.paused) {
                            audioList3.current.play();
                            setRotation2(index);
                          } else {
                            audioList3.current.pause();
                            setRotation2("");
                          }
                          audioList1.current.pause();
                          audioList2.current.pause();
                          audioList4.current.pause();
                          audioList5.current.pause();
                          audioList6.current.pause();
                        }
                        if (index === 3) {
                          if (audioList4.current.paused) {
                            audioList4.current.play();
                            setRotation2(index);
                          } else {
                            audioList4.current.pause();
                            setRotation2("");
                          }
                          audioList1.current.pause();
                          audioList2.current.pause();
                          audioList3.current.pause();
                          audioList5.current.pause();
                          audioList6.current.pause();
                        }
                        if (index === 4) {
                          if (audioList5.current.paused) {
                            audioList5.current.play();
                            setRotation2(index);
                          } else {
                            audioList5.current.pause();
                            setRotation2("");
                          }
                          audioList1.current.pause();
                          audioList2.current.pause();
                          audioList3.current.pause();
                          audioList4.current.pause();
                          audioList6.current.pause();
                        }
                      }}
                    >
                      <img src={item.imgUrl.default} alt="" className="video" />
                      <img
                        src={video_white_play.default}
                        alt=""
                        className={
                          rotation2 === index ? "video-play cur" : "video-play"
                        }
                      />
                    </div>
                  );
                })}
                <audio
                  onEnded={() => {
                    stopFn(1);
                  }}
                  src={audioSrc.default}
                  className="audio_el audioList"
                  ref={audioList1}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <audio
                  onEnded={() => {
                    stopFn(1);
                  }}
                  src={audioSrc2.default}
                  className="audio_el audioList"
                  ref={audioList2}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <audio
                  onEnded={() => {
                    stopFn(1);
                  }}
                  src={audioSrc3.default}
                  className="audio_el audioList"
                  ref={audioList3}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <audio
                  onEnded={() => {
                    stopFn(1);
                  }}
                  src={audioSrc4.default}
                  className="audio_el audioList"
                  ref={audioList4}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <audio
                  onEnded={() => {
                    stopFn(1);
                  }}
                  src={audioSrc5.default}
                  className="audio_el audioList"
                  ref={audioList5}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
              </div>
            </div>
            <div
              className="rightbgc"
              onClick={(e) => {
                audioList1.current.pause();
                audioList2.current.pause();
                audioList3.current.pause();
                audioList4.current.pause();
                audioList5.current.pause();
                audioList6.current.pause();
                setRotation3(false);
                setRotation2("");
                if (audioAt.current.paused) {
                  audioAt.current.play();
                  setRotation(true);
                } else {
                  audioAt.current.pause();
                  setRotation(false);
                }
              }}
            >
              {/* 到GTModal中区调用组件 */}
              <img src={rightbgc.default} alt="" className="right-bgc" />
              <img
                src={video_play.default}
                alt=""
                className={rotation === true ? "play-bgc cur" : "play-bgc"}
              />
              <audio
                onEnded={() => {
                  stopFn(2);
                }}
                src={audioSrc.default}
                id="audioA"
                className="audio_el"
                ref={audioAt}
              >
                您的浏览器不支持 audio 标签。
              </audio>
            </div>
          </div>
        </div>
      </TestStyle>
      <HomeLectures {...props} />
      {/* <HomeGrandtag {...props} /> */}
      <HomeGrandtagStyle>
        {
          <GTTitle
            // showlogo={true}
            // title={t("home.grandtag_props_title")}
            // position={"left"}
            mobieTitle="home.grandtag_props_title"
            // paddingSize="63px"
          />
        }
        <div className="wrap-v1">
          <Modal
            title={t("title")}
            visible={show}
            onOk={handleOk}
            width={"1000px"}
            onCancel={handleCancel}
            centered
            forceRender={false}
            destroyOnClose={true}
            closable={true}
            footer={null}
            closeIcon={<img src={close.default} alt="" />}
          >
            <div id="popupVidGrandtag" style={{ height: "100%" }}>
              <iframe
                width="100%"
                height="100%"
                src={"https://www.youtube.com/embed/ZoEcjD8tEuE?enablejsapi=1"}
                title={"GT Wealth"}
                frameBorder="0"
              ></iframe>
            </div>
          </Modal>
          <div className="container">
            <div className="content">
              {
                // <div className="title"> {t("home.grandtag_props_title")}</div>
                // <img src={detale_line.default} alt="" className="detale_lineimg" />
              }
              <div className="wrapper">
                <div className="content-v1 wap-hide" style={{ width: "95%" }}>
                  <img
                    src={top.default}
                    className="showlogo"
                    alt=""
                    style={{ width: "64px" }}
                  />
                  <div className="title">{t("home.grandtag_props_title")}</div>
                  <img src={left.default} alt="" style={{ width: "100%" }} />
                </div>
                <input type="checkbox" id="exp1" className="exp" />
                <div className="desc" ref={pRef}>
                  {t("home.grandtag_desc1")}
                </div>
                <label className="btn" htmlFor="exp1">
                  <div className="dropdown">
                    <img src={dropdown.default} alt="" />
                  </div>
                </label>
              </div>
              <div className="gtbutton">
                <GTButton
                  title={t("home.join_props_title")}
                  changeLink={(e) => {
                    props.history.push("/vision-career");
                  }}
                />
              </div>
            </div>

            <div className="advisor_bgc">
              <div
                className="advisor_img"
                // onClick={(e)=>{
                //   audioAt.current.pause();
                //   audioList1.current.pause();
                //   audioList2.current.pause();
                //   audioList3.current.pause();
                //   audioList4.current.pause();
                //   audioList5.current.pause();
                //   setRotation2('')
                //   if(audioList6.current.paused){
                //     audioList6.current.play();
                //     setRotation3(true)
                //   }else{
                //     audioList6.current.pause();
                //     setRotation3(false)
                //   }
                // }}
              >
                <img
                  src={advisor.default}
                  alt=""
                  className="img"
                  style={{ cursor: "initial" }}
                />
                {/* <div className={rotation3 === true?"advisor-icon play cur":"advisor-icon play"}>
                  <img src={play.default} alt="" />
                </div> */}
              </div>
              <div className="advisor-title">{t("home.grandtag_Team")}</div>
              <div className="hover">
                <div className="hover-icon">
                  <img
                    src={play_icon.default}
                    alt=""
                    style={{ cursor: "initial" }}
                  />
                </div>
                <div className="title fontstyle_SB">
                  {t("home.grandtag_Team")}
                </div>
              </div>
              <audio
                onEnded={() => {
                  stopFn(3);
                }}
                src={audioSrc6.default}
                id="audioB"
                className="audio_el"
                ref={audioList6}
              >
                您的浏览器不支持 audio 标签。
              </audio>
            </div>
            <div className="supporting_bgc">
              <div className="supporting-title">
                {t("home.grandtag_supporting1")}
                <br />
                {t("home.grandtag_supporting2")}
              </div>
              <div className="hover">
                <div className="hover-icon">
                  <img src={play_icon.default} alt="" />
                </div>
                <div className="title fontstyle_SB">
                  {t("home.grandtag_supporting1")}
                  <br />
                  {t("home.grandtag_supporting2")}
                </div>
              </div>
              <div className="supporting-img">
                <img
                  src={supporting.default}
                  alt=""
                  className="supporting_img"
                  style={{ cursor: "initial" }}
                />
                {/* <div className="supporting-icon play">
                  <img src={play.default} alt="" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="move-button">
            <GTButton
              changeLink={(e) => {
                props.history.push("/vision-career");
              }}
              title={t("home.join_props_title")}
              mobieTitle="routes-media.watch"
            />
          </div>
        </div>
      </HomeGrandtagStyle>
      <HomeReward {...props} />
      <GTFAQ {...props} />
      {/*<ModalVideo />*/}
      <GTTodo />
    </HomeWrapper>
  );
}

export default withRouter(GT_Home);
const HomeGrandtagStyle = styled.div`
  background-color: #fff;
  padding-bottom: 70px;
  .play {
    width: 48px;
    height: 48px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  img {
    cursor: pointer;
  }
  .exp {
    display: none;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content {
      flex: 2;
      padding-right: 40px;
      align-self: flex-start;
      .title {
        font-family: SossBo;
        font-size: 48px;
        color: rgb(6, 50, 94);
        line-height: 48px;
        padding: 10px 0;
      }
      .desc {
        width: 95%;
        overflow: hidden;
        font-size: 20px;
        line-height: 36px;
        margin-top: 100px;
        p {
          margin-bottom: 50px;
        }
      }
      .dropdown {
        display: none;
      }

      .detale_lineimg {
        display: block;
        width: 23px;
        height: 23px;
        margin: 10px 0;
      }

      .gtbutton {
        margin-top: 20px;
      }
    }

    .advisor_bgc {
      width: 240px;
      align-self: flex-start;
      margin-right: 3%;
      position: relative;
      top: 40px;
      &:hover {
        display: block;
        background-color: #f33500;
        .advisor_img {
          opacity: 0.15;
        }
        .advisor-title {
          display: none;
        }
        .advisor-icon {
          display: none;
        }

        .hover {
          width: 100%;
          display: block;
          position: absolute;
          top: 30%;
          left: 50%;
          transform: translate(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .hover-icon {
            height: 29px;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .title {
            padding-top: 20px;
            text-align: center;
            color: #ffff;
            font-size: 31px;
            line-height: 1.25em;
          }
        }
      }
      .advisor-title {
        display: block;
        font-family: SossBo;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        margin: 4px 0;
      }
      .advisor-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        transform: rotate(360deg);
      }
      .cur {
        animation: rotation 2s linear infinite;
      }
      @keyframes rotation {
        from {
          -webkit-transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(360deg);
        }
      }
      .hover {
        display: none;
      }
      .advisor_img {
        width: 100%;
        height: 478px;
        position: relative;
        .advisor-icon {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
        .img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .specialist_bgc {
      display: none;
      position: relative;
      .specialist-img {
        width: 104px;
        height: 209px;
        position: relative;
        .specialist_img {
          width: 100%;
          height: 100%;
        }
      }

      .specialist-title {
        text-align: center;
        padding-top: 0.5em;
        line-height: 33px;
        font-size: 19px;
      }
      .specialist-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: block;
      }
    }
    .supporting_bgc {
      align-self: flex-start;
      position: relative;

      &:hover {
        display: block;
        background-color: #f33500;

        .supporting-img {
          opacity: 0.15;
        }
        .supporting-title {
          color: transparent;
          background-color: #fff;
        }
        .advisor-icon {
          display: none;
        }

        .hover {
          width: 100%;
          display: block;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .hover-icon {
            height: 29px;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .title {
            padding-top: 20px;
            text-align: center;
            color: #ffff;
            font-size: 30px;
            line-height: 1.25em;
          }
        }
      }

      .supporting-title {
        display: block;
        font-family: SossBo;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        margin: 0;
        margin-bottom: 5px;
      }
      .hover {
        display: none;
      }
      .supporting-img {
        height: 536px;
        position: relative;
        .supporting-icon {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
        .supporting_img {
          height: 100%;
        }
      }
    }
  }

  .move-button {
    display: none;
  }
  .content-v2 {
    display: none;
  }
  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: 20px;
    .wap-hide {
      display: none;
    }
    .play {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .container {
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 0 4%;
      .content {
        margin: 0;
        flex: auto;
        padding: 0;
        .detale_lineimg {
          display: none;
        }
        .title {
          font-size: 18px;
          line-height: 24px;
          margin: 0;
          padding: 20px 0;
          padding-bottom: 10px;
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .desc {
            position: relative;
            display: -webkit-box;
            overflow: hidden;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 28px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            margin-top: 20px;
          }
          .btn {
            color: #fff;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
          }

          .exp {
            display: none;
          }
          .exp:checked + .desc {
            -webkit-line-clamp: 999;
          }
        }
        .dropdown {
          display: block;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .advisor_bgc {
        height: 230px;
        width: 130px;
        margin: 0;
        top: 15px;
        &:hover {
          background-color: transparent;
          .advisor_img {
            opacity: 1;
          }
          .advisor-title {
            display: block;
          }
          .advisor-icon {
            display: block;
          }

          .hover {
            display: none;
          }
        }
        .advisor_img {
          height: 100%;
          width: 100%;
          .img {
            width: 100%;
            height: 100%;
          }
          .advisor-icon {
          }
        }
        .advisor-title {
          font-size: 19px;
          line-height: 21px;
          margin-top: 7%;
        }
      }
      .specialist_bgc {
        display: block;
      }
      .supporting_bgc {
        &:hover {
          background-color: transparent;

          .supporting-img {
            opacity: 1;
          }
          .supporting-title {
            color: rgba(0, 0, 0, 0.85);
          }
        }

        .supporting-title {
          font-size: 19px;
          line-height: 21px;
        }
        .supporting-img {
          width: 131px;
          height: 253px;
          margin: 0 auto;
          .supporting_img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .move-button {
      display: block;
      padding-top: 6%;
      margin: 0 4%;
    }
  }
`;
