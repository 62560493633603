import React, { useState, useRef } from "react";
import { FooterWrapper, FooterMore, FooterMain } from "./style";
import { useTranslation } from "react-i18next";
import { Popover, message } from "antd";
import { saveInfo } from "@/service/video";

import GTTitle from "../GT_Title";

const address = require("@/assets/img/GTFoter_address@2x.png");
const contact = require("@/assets/img/GTFoter_contact@2x.png");
const messages = require("@/assets/img/GTFoter_message@2x.png");
const btn = require("@/assets/img/GTFooter_btn.png");

const circle_call = require("@/assets/img/GTFooter_circle_call@2x.png");
const circle_facebook = require("@/assets/img/GTFooter_circle_facebook@2x.png");
const circle_instagram = require("@/assets/img/GTFooter_circle_instagram@2x.png");
const circle_wechart = require("@/assets/img/GTFooter_circle_wechart@2x.png");
const circle_twitter = require("@/assets/img/GTFooter_circle_twitter@2x.png");
const circle_Youtub = require("@/assets/img/icon-youtube.png");
const wechat = require("../../assets/img/wechat.png");
export default function GT_Footer() {
  const { t } = useTranslation();
  const [footName, setFootName] = useState("");
  const [footNumber, setFootNumber] = useState("");
  const [footEmail, setFootEmail] = useState("");
  const [relFootName, setRelFootName] = useState("");
  const [relFootNumber, setRelFootNumber] = useState("");
  const [relFootEmail, setRelFootEmail] = useState("");
  const [footTextarea, setFootTextarea] = useState("");
  let year = new Date().getFullYear();
  const sendRequest = () => {
    if (footName !== "") {
      if (footNumber !== "") {
        if (footEmail !== "") {
          if (footTextarea !== "") {
            let reg =
              /^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
            if (reg.test(footEmail)) {
              saveInfo(
                footName,
                footNumber,
                footEmail,
                footTextarea,
                relFootName,
                relFootNumber,
                relFootEmail
              )
                .then((res) => {
                  if (res.code === 0) {
                    message.info(t("footer.sends.sendSuccess"));
                    setFootTextarea("");
                  }
                })
                .catch((err) => {
                  message.error(t("footer.sends.errsends"));
                });
            } else {
              message.error(t("footer.sends.erremail"));
              setFootEmail("");
            }
          } else {
            message.error(t("footer.sends.textarea"));
          }
        } else {
          message.error(t("footer.sends.email"));
        }
      } else {
        message.error(t("footer.sends.phone"));
      }
    } else {
      message.error(t("footer.sends.name"));
    }
  };
  const content = (
    <img src={wechat.default} alt="" style={{ width: "200px" }} />
  );

  const nameRef = useRef();
  const numberRef = useRef();
  const emailRef = useRef();
  const relatives_nameRef = useRef();
  const relatives_numberRef = useRef();
  const relatives_emailRef = useRef();
  const textareaRef = useRef();
  return (
    <FooterWrapper className="sprite_02">
      <GTTitle
        title={t("footer.learn_play")}
        color="#fff"
        showImg={"none"}
        position="left"
        mobieTitle="footer.learn_play"
      />
      <div className="wrap-v1">
        <h1
          style={{
            fontSize: 43,
            fontWeight: 700,
            color: "#fff",
            display: "none",
          }}
        >
          {t("footer.Learn a play")}
        </h1>
        <FooterMain>
          <div className="form">
            <div className="input-group fontstyle_SR">
              <input
                type="text"
                placeholder={t("footer.name")}
                required
                value={footName}
                onChange={(e) => {
                  if (nameRef.current.value.length > 15) {
                    setFootName(nameRef.current.value.slice(0, 15));
                  } else {
                    setFootName(nameRef.current.value);
                  }
                }}
                ref={nameRef}
              />
              <input
                type="number"
                placeholder={t("footer.phoneNum")}
                value={footNumber}
                required
                onChange={(e) => {
                  if (numberRef.current.value.length > 11) {
                    setFootNumber(numberRef.current.value.slice(0, 11));
                  } else {
                    setFootNumber(numberRef.current.value);
                  }
                }}
                ref={numberRef}
              />
              <input
                type="email"
                placeholder={t("footer.email")}
                required
                value={footEmail}
                onChange={(e) => {
                  if (emailRef.current.value.length > 25) {
                    setFootEmail(emailRef.current.value.slice(0, 25));
                  } else {
                    setFootEmail(emailRef.current.value);
                  }
                }}
                ref={emailRef}
              />
            </div>
            {/* <div className="input-group fontstyle_SR">
			  <input type="text" placeholder={t("footer.relatives_name")} value={relFootName} onChange={(e) => {
			    if (relatives_nameRef.current.value.length > 15) {
			      setRelFootName(relatives_nameRef.current.value.slice(0, 15))
			    } else {
			      setRelFootName(relatives_nameRef.current.value)
			    }
			  }
			  } ref={relatives_nameRef} />
			  <input type="number" placeholder={t("footer.relatives_phoneNum")}
			    value={relFootNumber} onChange={(e) => {
			      if (relatives_numberRef.current.value.length > 11) {
			        setRelFootNumber(relatives_numberRef.current.value.slice(0, 11))
			      } else {
			        setRelFootNumber(relatives_numberRef.current.value)
			      }
			    }
			    } ref={relatives_numberRef} />
			  <input type="email" placeholder={t("footer.relatives_email")} value={relFootEmail} onChange={(e) => {
			    if (relatives_emailRef.current.value.length > 25) {
			      setRelFootEmail(relatives_emailRef.current.value.slice(0, 25))
			    } else {
			      setRelFootEmail(relatives_emailRef.current.value)
			    }
			  }
			  } ref={relatives_emailRef} />
			</div> */}
            <textarea
              value={footTextarea}
              placeholder={t("footer.message")}
              onChange={(e) => {
                setFootTextarea(textareaRef.current.value);
              }}
              cols="30"
              rows="10"
              required
              ref={textareaRef}
            ></textarea>
            <button className="gt_footer_button" onClick={sendRequest}>
              <div className="title fontstyle_SB">{t("footer.send")}</div>
              <img src={btn.default} alt="" />
            </button>
          </div>
          <div className="fd-nav fontstyle_SPR">
            {/* <div className="fd-list">
              <div>
                <img src={address.default} alt="" className="addressstyle" />
              </div>
              <div className="fontstyle_SB">{t("footer.address")}</div>
              <div className="font_opcticy">
                {t("footer.address_info")}
                <br />
                {t("footer.hours")}
              </div>
            </div>
            <div className="fd-list">
              <div>
                <img src={contact.default} alt="" className="contactstyle" />
              </div>
              <div className="fontstyle_SB">{t("footer.contact_info")}</div>
              <div className="font_opcticy">
                {t("footer.fax")}
                <br />
                {t("footer.phone")}
              </div>
            </div> */}
            <div className="fd-list">
              <div>
                <img src={messages.default} alt="" className="messagestyle" />
              </div>
              <div className="fontstyle_SB">{t("footer.support")}</div>
              <div className="font_opcticy">
                {t("footer.support_info")}
                <br />
                {t("footer.sales")}
              </div>
            </div>
          </div>
        </FooterMain>
      </div>
      <FooterMore>
        <div className="wrap-v1 footer">
          <div className="footer-left ">
            {t("footer.copyright")}
            {year}
            {t("footer.copyright2")}
            <div className="footer_copyright">
              {t("footer.copyright3")}
              <a
                href="https://activatortube.com/"
                target="_blank"
                rel="noreferrer"
                className="footer_copyright_link"
              >
                {t("footer.copyright4")}
              </a>
            </div>
          </div>
          <div className="footer-right">
            <ul>
              <li>
                <Popover
                  content={content}
                  title={t("topbanner.wechat_connect")}
                  placement="top"
                  overlayInnerStyle={{
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  <img src={circle_wechart.default} alt="" />
                </Popover>
              </li>
              <li
                onClick={(e) => {
                  window.open("https://www.facebook.com/GTWealthCanada/");
                }}
              >
                <img src={circle_facebook.default} alt="" />
              </li>
              <li
                onClick={(e) => {
                  window.open("https://www.youtube.com/user/GrandtagGroup");
                }}
              >
                <img src={circle_Youtub.default} alt="" />
              </li>
              {/* <li
                onClick={(e) => {
                  window.open("https://www.youtube.com/watch?v=ZoEcjD8tEuE");
                }}
              >
                <img src={circle_twitter.default} alt="" />
              </li>
              <li
                onClick={(e) => {
                  window.open("https://www.youtube.com/watch?v=ZoEcjD8tEuE");
                }}
              >
                <img src={circle_instagram.default} alt="" />
              </li> */}
              <li
                onClick={(e) => {
                  window.open("tel:6047612888");
                }}
              >
                <img src={circle_call.default} alt="" />
              </li>
            </ul>
          </div>
        </div>
      </FooterMore>
    </FooterWrapper>
  );
}
