/**
 * 此组件用于实现home页面下的lecture块进行数据轮播的装载容器
 * 参数:
 *  info:定义数组数据
 *  img:目前给定静态图,后续传入props数据
 */

import React, { useState, useEffect } from "react";
import GTModalVideo from "@/pages/GT_ModalVideo";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getVideo } from "../../../service/video";
const logo = require("@/assets/img/Home_lectureslogo@2x.png");
const lectures_video = require("../../../assets/img/Lectures_videos@2x.png");
const GTHomeLecturesVideoStyle = styled.div`
  margin-bottom: 50px;
   transition: all .2s;
  .container {
    width: 320px;
    height: 256px;
    position: relative;

    .bgc {
      width: 100%;
      height: 100%;
      .video_bgc {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height:100%;
          border-radius: 5px;
        }
      }
      .video_center {
        display: none;
      }
    }
    .welt {
      width: 260px;
      height: 70px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ececec;
      .text {
        width: 75%;
        height: 40px;
        overflow: hidden;
        font-size: 14px;
        line-height: 20px;
        color: #444953;
      }
      img {
        width: 24px;
        height: 16px;
      }
    }
  }

  /* pc端悬浮效果 */
  &:hover {
    position: relative;
    .bgc {
      height: 256px;
      background-color: #f33500;
	  border-radius: 5px;
      .video_bgc {
        opacity: 0.1;
      }
      .video_center {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%);
        display: block;
        img {
          width: 84px;
          height: 57px;
        }
      }
    }

    .welt {
      .text {
        width: 95%;
      }
      img {
        display: none;
      }
    }
  }
	@media (min-width:800px) and (max-width: 1400px) {
		.container{
			width: 250px;
			height: auto;
			.welt{
				width: 220px;
				bottom: -23px;
			}
		}
		&:hover {
			.bgc {
				height: auto;
			}
		}
	}
  /* 自适应效果 */
  @media (max-width: 768px) {
    margin: 0;
    .container {
      width: 160px;
      height: 140px;
      box-sizing: border-box;
      .bgc {
        height: auto;
      }
      .welt {
        width: 90%;
        height: 40px;
        bottom: -10px;
        padding: 0 4%;
        border-radius: 5px;

        .text {
          width: auto;
          font-size: 12px;
          line-height: 18px;
          height: 3em;
          margin-right: 3px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          flex:1;
        }
        img {
          width: 18.49px;
          height: 12.77px;
        }
      }
    }

    &:hover {
      .bgc {
        background-color: transparent;

        .video_bgc {
          opacity: 1;
          img {
            height: 128px;
          }
        }
        .video_center {
          display: none;
        }
      }

      .welt {
        .text {
          width: auto;
        }
        img {
          display: block;
        }
      }
    }
  }
`;

export default function DVideo(props) {
  const { info, index } = props;
  const [show, setShow] = useState(false);
  const lng = window.localStorage.getItem('i18nextLng');
  // 数据请求不到
  const [lecturesVideo, seyLecturesVideo] = useState([1, 2]);
  const { t } = useTranslation();
  const showModal = () => {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {
        info: {link: lng === 'en'?info.en:lng === 'zh-CN'?info.zhCn:info.zhTw,title: t(info.title)},
      });
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    // getVideo(info.type).then((res) => {
    //   seyLecturesVideo(res.data);
    // });
  }, [info.type]);
  return (
    <GTHomeLecturesVideoStyle className="cursor">
      {/* {lecturesVideo
        ? lecturesVideo.slice(0, 1).map((item, index) => {
          return <GTModalVideo show={show} setShow={setShow} videourl={item} key={item} id={item.id + "lectures"} />;
        })
        : []} */
      }
      <GTModalVideo videourl={{link: lng === 'en'?info.en:lng === 'zh-CN'?info.zhCn:info.zhTw,title: t(info.title),}} show={show} setShow={setShow} id={index + "lectures"} />
      <div className="container">
        <div
          className="bgc"
          onClick={(e) => {
            showModal();
          }}
        >
          <div className="video_bgc">
            <img src={lng=='en'?info.imgUr2.default:info.imgUrl.default} alt="" />
          </div>
          <div className="video_center">
            <img src={lectures_video.default} alt="" />
          </div>
        </div>
        <div className="welt">
          <div className="text">{t(info.title)}</div>
          <img src={logo.default} alt="" />
        </div>
      </div>
    </GTHomeLecturesVideoStyle>
  );
}
