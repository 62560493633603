import React, { useState } from "react";
import styled from "styled-components";
import GTModalVideo from "@/pages/GT_ModalVideo";
import { useTranslation } from "react-i18next";
const right_bgc = require("../../../assets/img/ychfm.jpg");
const left_line = require("../../../assets/img/Lectures_line@2x.png");
const play = require("../../../assets/img/Lectures_video_play@2x.png");
const right_transverse_bgc = require("../../../assets/img/ychfm.jpg");
const Ss = styled.div`
  background-color: #f2f5f7;
  position: relative;
  .container {
    display: flex;
    padding-top: 60px;
    padding-bottom: 40px;
    .content {
      flex: 1;
      .title {
        font-size: 54px;
        line-height: 76px;
        color: #002369;
      }

      .small-title {
        font-size: 40px;
        line-height: 50px;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        .span-title {
          color: #f33500;
        }

        .em-red {
          flex-basis: 154px;
          background-color: #f33500;
          padding: 0.3em 0.8em;
          line-height: 1.25em;
          /* line-height: 60px; */
          font-size: 23px;
          display: inline-block;
          color: #fff;
          border-radius: 33px;
          border-bottom-left-radius: 0;
        }
      }

      .desc {
        font-size: 16px;
        line-height: 36px;
        p {
          padding-top: 1em;
        }
      }
    }
    .side-transverse-bgc {
      display: none;
    }
    .side-bgc {
      position: relative;
      width:500px;
      height: 794px;
      margin-left: 40px;
      display: block;
      .bgc {
        width:100%;
        height: 794px;
        img {
          width:100%;
          height: 100%;
        }
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-30%, -50%);
        width: 101px;
        height: 101px;
      }
    }
  }
  .position_line {
    position: absolute;
    height: 302px;
    z-index: -1;
    top: 40%;
    left: -2%;
    img {
      height: 100%;
    }
  }
	@media (min-width:800px) and (max-width: 1400px) {
		.container {
			.content {
				.title {
				  font-size: 40px;
				}
				.small-title{
					font-size:24px;
					.em-red{
						font-size:20px;
					}
				}
				.desc{
					font-size:14px;
				}
			}
		}
	}
  @media (max-width: 768px) {
    background-color: #fff;
    .container {
      flex-direction: column;
      padding: 4%;
      padding-bottom: 12%;
      .content {
        .title {
          font-size: 27px;
          line-height: 30px;
          padding-bottom: 10px;
        }
        .small-title {
          font-size: 18px;
          padding-bottom: 0.5em;
          line-height: 1.25em;
          .em-red {
            flex-basis: auto;
            padding: 0.1em 0.5em;
            font-size: 18px;
          }
        }
        .desc {
          font-size: 16px;
          line-height: 28px;

          p {
            padding-top: 0;
            padding-bottom: 1em;
          }
        }
      }
      .side-bgc {
        display: none;
      }

      .side-transverse-bgc {
        display: block;
        position: relative;
        display: block;
        width: 100%;
        .bgc {
          width: 100%;

          img {
            width: 100%;
          }
          &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-30%, -50%);
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  @media (max-width: 412px) {
    .container {
      .content {
        .title {
          font-size: 24px;
        }

        .small-title {
          .span-title {
            font-size: 17px;
          }
          .em-red {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 376px) {
    .container {
      .content {
        .title {
          font-size: 22px;
        }

        .small-title {
          .span-title {
            font-size: 15px;
          }
          .em-red {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 321px) {
    .container {
      .content {
        .title {
          font-size: 20px;
          line-height: 23px;
        }

        .small-title {
          padding: 0;
          .span-title {
            font-size: 13px;
          }
          .em-red {
            font-size: 13px;
          }
        }

        .desc {
          margin-top: 10px;
        }
      }
    }
  }
`;

export default function Index(props) {
  // const { info } = props;
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {});
    } else {
      setShow(true);
    }
  }
  return (
    <>
      <Ss>
        <div className="container wrap-v1">
          <div className="content">
            <div className="title fontstyle_SB">{t("lectures.title")}</div>
            <div className="small-title ">
              <span className="span-title fontstyle_SB">
                {t("lectures.small-title")}
              </span>
              <span className="em-red">{t("lectures.em-red")}</span>
            </div>
            <div className="desc">
              <p>{t("lectures.desc1")}</p>
              <p>{t("lectures.desc2")}</p>
              <p>{t("lectures.desc3")}</p>
              <p>
                {t("lectures.desc4")} {t("lectures.clickWebsite")}{" "}
                <a
                  style={{ color: "red",textDecoration: 'underline' }}
                  target="_blank"
                  href="http://lifewealth.sv.mikecrm.com/4jr5ncH"
                  rel="noreferrer"
                >
                  {t("lectures.website")}
                </a>{" "}
                {t("lectures.new")}{" "}
              </p>
            </div>
          </div>
          <div
            className="side-bgc"
            // onClick={(e) => {
            //   playVideo();
            // }}
          >
            <div className="bgc">
              <img src={right_bgc.default} alt="" />
            </div>
            {/* <img src={play.default} alt="" className="play" /> */}
          </div>
          <div
            className="side-transverse-bgc"
            // onClick={(e) => {
            //   playVideo();
            // }}
          >
            <div className="bgc">
              <img src={right_transverse_bgc.default} alt="" />
            </div>
            {/* <img src={play.default} alt="" className="play" /> */}
          </div>
        </div>
        <div className="position_line">
          <img src={left_line.default} alt="" />
        </div>
      </Ss>
      <GTModalVideo show={show} setShow={setShow} id={"LectureHeader"} />
    </>
  );
}
