import { Tabs, Timeline } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
import * as dayjs from "dayjs";
import { Translation } from "react-i18next";
import { ImageURL } from "../../../service/config";
import RewardNav from "../RewardNav";
import GTButton from "../../../pages/GT_Button";
import { getNews, getDict } from "../../../service/video";

const { TabPane } = Tabs;

const date = require("@/assets/img/Reward_tabs_date@2x.png");
const line = require("@/assets/img/Reward_tabs_line@2x.png");
const Image = function (pic) {
  return ImageURL + pic;
};
const name = (v1, v2, v3) => {
  if (localStorage.getItem("i18nextLng") === "zh-CN") {
    return v1;
  }
  if (localStorage.getItem("i18nextLng") === "en") {
    return v2;
  }
  return v3;
};
export default class RewardTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mypage: 1,
      mysize: 5,
      hasmore: false,
      data: [],
      type: "",
      count: 0,
      isShow: false,
      tabType: [],
    };
  }

  getCount = (e, t) => {
    return getNews(e, t, 10000).then((res) => {
      setTimeout(() => {
        if (res) {
          this.setState({
            count: res.data?.length,
          });
        }
      }, 500);
    });
  };

  getData = (e, t) => {
    this.setState({
      mypage: e,
      type: t,
    });
    return getNews(e, t, this.state.mysize)
      .then((res) => {
        // console.log("请求数据Data" + res.data)
        if (res.data.length < 5) {
          this.setState({
            isShow: true,
          });
        } else {
          this.setState({
            isShow: false,
          });
        }
        if (e === 1) {
          this.setState({
            hasmore: res.data.length === this.state.mysize,
            data: res.data,
          });
        } else {
          // setHasmore(res.data.length < count ? false : true);
          this.setState({
            // hasmore: this.state.data.length === this.state.mysize,
            // hasmore: this.state.data.length = this.state.count ? true : false,
            data: this.state.data.concat(res.data),
          });
        }
        // console.log("push进去的数据"+this.state.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    // this.getCount(1, this.state.type);
    this.getData(this.state.mypage, this.state.type);
    getDict("NEWS_TYPE").then((res) => {
      if (res.code === 0) {
        const li = [
          {
            code: "",
            id: "",
            nameEn: "All",
            nameZHCN: "全部",
            nameZHTW: "全部",
            parent: "NEWS_TYPE",
            remarks: "全部",
          },
        ];
        res.data.forEach((item) => {
          li.push(item);
        });
        this.setState({
          tabType: li,
        });
      }
    });
  }

  render() {
    const types = [
      "",
      "Housing_loan",
      "Commercial_loans",
      "Financial_Investment",
      "Insurance_Services",
      "Wending_field",
      "Corporate_Culture",
    ];
    const { mypage, type, data } = this.state;
    return (
      <Translation>
        {(t, index) => {
          return (
            <RewardTabsWrapper key={index}>
              {/* 导航面包屑 */}
              {/* <RewardNav /> */}
              <RewardTabsWarpper className="wrap-v1">
                <Tabs
                  defaultActiveKey={0}
                  type={"card"}
                  // tabBarGutter={40}
                  tabBarStyle={{ marginRight: 20 }}
                  onTabClick={(e) => {
                    // this.getCount(1, types[e]);
                    this.getData(1, e);
                  }}
                >
                  {this.state.tabType?.map((i, index) => {
                    return (
                      <TabPane
                        tab={name(i.nameZHCN, i.nameEn, i.nameZHTW)}
                        key={i.code}
                      >
                        <Timeline mode={"left"}>
                          {data &&
                            data.map((item) => {
                              return (
                                <Timeline.Item
                                  label={dayjs(item.updatedAt)
                                    .locale("zh-CN")
                                    .format("MM-DD-YYYY")}
                                  color="#F33500"
                                  position={"left"}
                                  key={item.id}
                                  onClick={() => {
                                    this.props.history.push("/reward-detail", {
                                      id: item.id,
                                      title: item.title,
                                      updatedAt: item.updatedAt,
                                      content: item.content,
                                      desc: item.digest,
                                    });
                                    // const w = window.open("about:blank");
                                    // w.location.href = "/reward-detail/id=" + item.id;
                                    // w.history.state = item.id;
                                  }}
                                >
                                  <div className="container">
                                    <div className="produce-picture">
                                      <img src={Image(item.pic)} alt="" />
                                    </div>

                                    <div className="content">
                                      <div className="title">{item.title}</div>
                                      <div className="desc">{item.digest}</div>
                                      <div className="tail-info-bar">
                                        <div className="tail-date">
                                          <img src={date.default} alt="" />
                                          <span>
                                            {dayjs(item.createdAt)
                                              .locale("zh-CN")
                                              .format("MM-DD-YYYY")}
                                          </span>
                                        </div>
                                        <div className="tail-info-leter">
                                          <span className="red_text">
                                            {t("home.join_props_small_title")}
                                          </span>
                                          <img
                                            src={line.default}
                                            alt=""
                                            className="line-icon"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Timeline.Item>
                              );
                            })}
                          {this.state.isShow === false ? (
                            <GTButton
                              poscenter="center"
                              bgcColor={"#F33500"}
                              changeLink={() => {
                                this.getData(mypage + 1, type);
                              }}
                            />
                          ) : null}
                        </Timeline>
                      </TabPane>
                    );
                  })}
                </Tabs>
              </RewardTabsWarpper>
            </RewardTabsWrapper>
          );
        }}
      </Translation>
    );
  }
}

const RewardTabsWrapper = styled.div`
  padding-bottom: 50px;
  .nav {
    height: 53px;
    display: flex;
    align-items: center;
    margin: 35px 0;

    .ant-breadcrumb-separator {
      margin: 0 20px;
    }
    .active {
      color: #f33500;
    }
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const RewardTabsWarpper = styled.div`
  display: block;
  .ant-tabs-nav {
    padding-top: 5px;
    &:before {
      border: 0 !important;
    }
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      margin-right: 20px !important;
      border-radius: 0 !important;
      /* 新闻详情Tab组件导航栏个体宽度样式修改 */
      min-width: 129px;
      box-sizing: border-box;
      margin: 20px 0;
      height: 48px;
      border: #ccc 2px solid !important;
      font-size: 16px;
      line-height: 21px;
      color: #444953;
      text-align: center;
      justify-content: center;
      align-items: center;
      &:hover {
        color: #444953 !important;
      }
    }
    .ant-tabs-tab-active {
      background-color: #f33500 !important;
      border: 1px solid #f33500 !important;
      .ant-tabs-tab-btn {
        color: #fff !important;
      }
    }
  }
  .ant-timeline {
    margin-top: 4%;
  }
  .ant-timeline-item {
    padding-bottom: 50px;
  }
  .ant-timeline-item-left {
    margin: 0;
    margin-left: -85%;
  }
  .ant-timeline-item-label {
    color: #999999;
    padding-right: 15px;
    background-color: #efefef;
    width: 102px;
    height: 26px;
    font-size: 12px;
    line-height: 28px;
    font-weight: 700;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 210px;
    margin-left: 2%;
    cursor: pointer;

    .produce-picture {
      width: 346px;
      height: 194px;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content {
      height: 100%;
      padding: 0 3% 0 3%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      flex: 1;
      .title {
        line-height: 1.25em;
        font-size: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .desc {
        flex-shrink: 0;
        font-size: 16px;
        overflow: hidden;
        line-height: 1.45em;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .tail-info-bar {
        display: flex;
        justify-content: space-between;
        background-color: #f7f7f7;
        height: 44px;
        padding: 0 10px;

        .tail-date {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-basis: 20%;
          span {
            margin-left: 6%;
          }
          img {
            height: 16px;
            width: 16px;
          }
        }

        .tail-info-leter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          /* flex-basis: 13%; */

          .looks-icon {
            width: 16px;
            height: 11px;
          }
          span {
            padding-right: 15px;
            color: #f33500;
          }
          .vers-line {
          }
          .line-icon {
            align-self: center;
            width: 18px;
            height: 4px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        /* 新闻详情Tab组件导航栏个体宽度样式修改 */
        border-radius: 0 !important;
        min-width: 100px;
        height: 30px;
        padding: 15px 0 !important;
        border: #ccc 2px solid !important;
        font-size: 14px;
        line-height: 19px;
        color: #444953;
        &:hover {
          color: #444953 !important;
        }
      }
      .ant-tabs-tab-active {
        background-color: #f33500 !important;
        border: 1px solid #f33500 !important;

        .ant-tabs-tab-btn {
          color: #fff !important;
        }
      }
    }
  }
`;
