import React, { useRef } from "react";
import styled from "styled-components";
import GTTitle from "../../../pages/GT_Title";
import GTButton from "../../../pages/GT_Button";
import { useTranslation } from "react-i18next";
import GTLecturesVideo from "../../../pages/GT_LecturesVideo";
const iconlogo = require("../../../assets/img/Home-Lectures_iconlogo@2x.png");
const redLine = require("../../../assets/img/Home_Lecture_redline@2x.png");
const line = require("../../../assets/img/Home_Lectures_line@2x.png");
const lectures_video = require("../../../assets/img/Lecture_buttonicon@2x.png");
const whitelogo = require("@/assets/img/Up_logo@2x.png");
const HomeLecturesWrapper = styled.div`
  position: relative;
  display: block;
  .content {
    .lectures-title {
      .button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        button {
          height: 60px;
          width: 213px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          cursor: pointer;
          transition: all 0.5s ease-in-out;
          color: #f33500;
          &:hover {
            background-color: #f33500 !important;
            border: 0;
            color: #fff;
            border: 2px #f33500 solid;
          }
          .button-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
          }

          .lectures_img {
            width: 33px;
            height: 7px;
          }
        }
      }
      .iconlogo {
        width: 28.96px;
        height: 26.08px;
        position: relative;
        top: 65px;
        left: 28%;
      }

      .title {
        font-size: 48px;
        color: #fff;
        font-weight: 700;
        padding: 15px 0;
      }

      .lineimg {
        width: 490px;
      }
    }

    .mobie-title {
      display: none;
    }

    .mobie-button {
      display: none;
    }
  }
  .redline {
    width: 107px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 12%;
  }

  @media (max-width: 768px) {
    display: none;
    .content {
      .lectures-title {
        display: none;
        .button {
          display: none;
        }
      }

      .mobie-title {
        display: block;
      }

      .mobie-button {
        padding: 0 4%;
        padding-bottom: 6%;
        display: block;
      }
    }

    .redline {
      display: none;
    }
  }
`;

const HomeLecturesMobieWrapper = styled.div`
  position: relative;
  display: none;
  .content {
    .lectures-title {
      padding-bottom: 1%;
      .button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        button {
          height: 60px;
          width: 213px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          cursor: pointer;

          background-color: #f33500 !important;
          .button-title {
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
          }

          .lectures_img {
            width: 33px;
            height: 7px;
          }
        }
      }
      .iconlogo {
        width: 26px;
        height: 29px;
        position: relative;
        top: 65px;
        left: 19%;
      }

      .title {
        font-size: 48px;
        color: #fff;
        font-weight: 700;
        padding-top: 15px;
      }

      .lineimg {
        width: 490px;
      }
    }

    .mobie-title {
      display: none;
    }

    .mobie-button {
      display: none;
    }
  }
  .redline {
    width: 107px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 12%;
  }

  @media (max-width: 768px) {
    display: block;
    .content {
      .lectures-title {
        display: none;
        .button {
          display: none;
        }
      }

      .mobie-title {
        display: block;
      }

      .mobie-button {
        padding: 0 4%;
        padding-bottom: 6%;
        display: block;
      }
    }

    .redline {
      display: none;
    }
  }
`;

export default function HomeLectures(props) {
  const { t } = useTranslation();
  const imgsRef = useRef();
  return (
    <>
      <HomeLecturesWrapper className="sprite_08">
        <div className="wrap-v1  ">
          <div className="content">
            <div className="lectures-title">
              <img src={iconlogo.default} alt="" className="iconlogo" />
              <div className="title">{t("home.lectures_props_title")}</div>
              <img src={line.default} alt="" className="lineimg" />
              {/* 规格按钮组件引用起来好像不是很方便 */}
              <div className="button">
                <button
                  onClick={(e) => {
                    props.history.push("/lectures");
                  }}
                  onMouseOut={(e) => {
                    imgsRef.current.src = whitelogo.default;
                  }}
                  onMouseOver={(e) => {
                    imgsRef.current.src = lectures_video.default;
                  }}
                >
                  <div className="button-title">
                    {t("home.join_props_title")}
                  </div>
                  <img
                    src={whitelogo.default}
                    alt=""
                    className="lectures_img"
                    ref={imgsRef}
                  />
                </button>
              </div>
            </div>
            <GTTitle
              mobieTitle={"LIVE LECTURES"}
              className="mobie-title"
              show={"none"}
            />
            <GTLecturesVideo padding="60px" />
            <div className="mobie-button">
              <GTButton
                mobieTitle={"home.join_props_title"}
                changeLink={(e) => {
                  props.history.push("/lectures");
                }}
              />
            </div>
          </div>
        </div>
        <img src={redLine.default} alt="" className="redline" />
      </HomeLecturesWrapper>

      <HomeLecturesMobieWrapper>
        <div className="wrap-v1 ">
          <div className="content">
            <GTTitle
              mobieTitle={"home.lectures_props_title"}
              className="mobie-title"
              show={"none"}
            />
            <div className="sprite_08">
              <GTLecturesVideo />
              <div className="mobie-button" style={{ paddingTop: "6px" }}>
                <GTButton
                  mobieTitle="routes-media.watch"
                  changeLink={(e) => {
                    props.history.push("/lectures");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <img src={redLine.default} alt="" className="redline" />
      </HomeLecturesMobieWrapper>
    </>
  );
}
