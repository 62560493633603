import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import GTModalVideo from "@/pages/GT_ModalVideo";
import YouTube from 'react-youtube';

const img = require("@/assets/img/8fm.jpg");
const video_play = require("../../../assets/img/Testimonials_video_play@2x.png");
const poster = require("../../../assets/img/8fm.jpg");
const play = require("../../../assets/img/Vision_play@2x.png");
const HeaderStyle = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  padding: 50px 100px 50px 100px;
  align-items: center;
  .content {
    width:50%;
    display: flex;
    flex-direction: column;
    margin-right: 100px;
    .title-words {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .title {
        font-size: 76px;
        line-height: 121px;
        color: #fff;
        text-shadow: -1px 1px 0 #f33500, 1px 1px 0 #f33500, 1px -1px 0 #f33500,
          -1px -1px 0 #f33500;
      }
      .how_title {
        font-size: 36px;
        color: #002369;
        line-height: 48px;
      }
    }

    .desc {
      font-size: 16px;
      color: #7d7d7d;
      p {
        padding-top: 2em;
      }
    }

    .ss_bgc {
      display: none;
    }
  }

  .specialties_bgc {
    width:50%;
    height: 464px;
    position: relative;
    cursor: pointer;
    img {
      height: 100%;
    }
    .poster{
      width:100%;
      height:100%;
      position: absolute;
      top:0;
      left:0;
      .posterImg{
        width:100%;
        height:100%;
      }
      .posterIcon{
        width:100px;
        height:100px;
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
      }
    }
    .poster.cur{
      display:none;
    }
	img.video-play{
		width:100px;
		height:100px;
		position: absolute;
		top:50%;
		left:50%;
		transform: translate(-50%, -50%);
	}
  }
	@media (min-width:800px) and (max-width: 1400px) {
		align-items:flex-start;
		.content {
			width:calc(60% - 100px);
			.title-words {
				.title {
					font-size:66px;
				}
				.how_title{
					font-size:30px;
				}
			}
			.desc{
				font-size:14px;
			}
		}
		.specialties_bgc{
			width:40%;
			display:flex;
			align-items:center;
			justify-content:center;
			overflow: hidden;
			margin-top:74px;
		}
	}
  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
    align-items: flex-end;
    background-color: #fff;
    border-bottom: 20px #F7F9FC solid;
    .content {
      margin: 0 4%;
      width: 100%;
      justify-content: flex-start;
      height: auto;
      .title-words {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        padding: 3% 0;
        .title {
          font-size: 30px;
          line-height: 1em;
          padding-right: 30px;
        }

        .how_title {
          font-size: 24px;
          line-height: 22px;
          margin: 0;
          margin: 3% 0;
        }
      }

      .ss_bgc {
        display: block;
        position: relative;
        width:100%;
        height:300px;
        .poster{
          width:100%;
          height:100%;
          position: absolute;
          top:0;
          left:0;
          .posterImg{
            width:100%;
            height:100%;
          }
          .posterIcon{
            width:50px;
            height:50px;
            position: absolute;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            z-index:1;
          }
        }
        .poster.cur{
          display:none;
        }
        >div{
          width:100%;
          height:100%;
          iframe{
            width:100%;
            height:100%;
          }
        }
        img {
          width: 100%;
          height: 220px;
          z-index: -1;
        }
		img.video-play{
			width: 60px;
			height: 60px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
		}
      }

      .flex-desc {
        display: flex;
        flex-direction: column;
        .desc {
          color: #333333;
          font-size: 18px;
          line-height: 30px;
          overflow: hidden;
          padding-bottom: 1.5em ;
          p {
            padding-top: 1em;
          }
        }
      }
    }

    .specialties_bgc {
      display: none;
    }
  }

  @media (max-width: 333px) {
    .content {
      .title-words {
        .title {
          font-size: 28px;
        }

        .how_title {
          font-size: 20px;
        }
      }

      .flex-desc {
        .desc {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
`;

export default function Specialties_header(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [playV, setplayV] = useState();
  const [playV2, setplayV2] = useState();
  const [videoState, setVideoState] = useState(false);
  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
		props.history.push("/video-detail", {});
    } else {
      setShow(true);
    }
  }
  const opts = {
    width: '100%',
    playerVars: { 
      autoplay: 1,
      rel:0,
      mute:1,
      loop:1,
      playlist:'XYiRFVAnnNo'
    }
  };
  const opts2 = {
    width: '100%',
    playerVars: { 
      autoplay: -1,
      rel:0,
      mute:1,
      loop:1,
      playlist:'XYiRFVAnnNo'
    }
  };
  const _onReady = (event)=>{
    setplayV(event.target)
  }
  const _onReady2 = (event)=>{
    setplayV2(event.target)
  }
  const pl = (e) =>{
    setVideoState(true)
    playV.playVideo()
  }
  const pl2 = (e) =>{
    setVideoState(true)
    playV2.playVideo()
  }
  const onPlayerStateChange = (e) =>{
    if(e.data === 1){
      setVideoState(true)
    }
  }
  const onPlayerStateChange2 = (e) =>{
    if(e.data === 1){
      setVideoState(true)
    }
  }
  return (
    <HeaderStyle>
      <div className="content">
        <div className="title-words">
          <div className="title fontstyle_SB"> {t("specialties.title")}</div>
          <div className="how_title fontstyle_SB">
            {t("specialties.how_title1")}
          </div>
        </div>
        <div className="ss_bgc">
          <YouTube
            videoId={'XYiRFVAnnNo'}
            opts={opts2}
            onReady={_onReady2}
            onStateChange={onPlayerStateChange2}
          />
          {/* <img src={img.default} alt="" />
          <img src={video_play.default} alt="" className="video-play " /> */}
          <div className={videoState === true? 'poster cur': 'poster'}
            onClick={(e) => {
              pl2()
            }}
          >
            <img className="posterImg" src={poster.default} />
            <img className="posterIcon" src={play.default} />
          </div>
        </div>
        <div className="flex-desc">
          <div className="desc fontstyle_SPR">
            <p>{t("specialties.desc_p1")}</p>
            <p>{t("specialties.desc_p2")}</p>
          </div>
        </div>
      </div>
      <div
        className="specialties_bgc" 
        onClick={
          (e) => {
            // playVideo();
          }
        }
      >
        <YouTube
          videoId={'XYiRFVAnnNo'}
          opts={opts}
          onReady={_onReady}
          onStateChange={onPlayerStateChange}
        />
        <div className={videoState === true? 'poster cur': 'poster'}
          onClick={(e) => {
            pl()
          }}
        >
          <img className="posterImg" src={poster.default} />
          <img className="posterIcon" src={play.default} />
        </div>
        {/* <img src={img.default} alt="" />
        <img src={video_play.default} alt="" className="video-play " /> */}
      </div>
      <GTModalVideo videourl={{link: "https://youtu.be/XYiRFVAnnNo",title: t('title'),}} show={show} setShow={setShow} id="TestimonialsHeader" />
    </HeaderStyle>
  );
}
