import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import GTTitle from "@/pages/GT_Title";

const address = require("@/assets/img/contect_adress@2x.png");
const contact = require("@/assets/img/contect_message@2x.png");
const message = require("@/assets/img/Contact_message@2x.png");
const map = require("@/assets/img/contect_map@2x.jpg");
const bgc = require("../../../assets/img/Contact_us_Burnaby_bgc@2x.jpg");
const bgc1 = require("../../../assets/img/bg-01.png");
const bgc2 = require("../../../assets/img/contectus_bgc2@2x.jpg");
const bgc3 = require("../../../assets/img/contectus_bgc3@2x.jpg");
const bgc4 = require("../../../assets/img/contectus_bgc4@2x.jpg");
const line = require("@/assets/img/contect_line@2x.png");
const map1 = require("@/assets/img/map-01.png");
const map2 = require("@/assets/img/map-02.png");
const map3 = require("@/assets/img/map-03.png");
const map4 = require("@/assets/img/map-04.png");
const map5 = require("@/assets/img/map-05.png");
const map6 = require("@/assets/img/map-06.png");
const map7 = require("@/assets/img/map-07.png");
const ContectUsStyle = styled.div`
  margin: 60px auto 100px auto !important;
  .contect-wrap {
    position: relative;
    .contect-container {
      padding-top: 100px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      .item-padding {
        padding-left: 13.5%;
      }
      .center {
        position: relative;
        .map_line {
          width: 74px;
          height: 66px;
          position: absolute;
          padding-bottom: 5px;
          top: 30%;
          left: 50%;
          transform: translate(-30%);
        }
      }
      .contect-item {
        .contect-mobie-title {
          display: none;
        }
        .contect-nav {
          height:350px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          background-color: #f6f6f6;
          padding: 40px 30px;
          padding-right: 0;
          padding-bottom: 0;
          .contect-bgc {
            display: none;
          }
          .contect-list {
            display: grid;
            grid-template-rows: [r1-start] 40px [r1-end r2-start] 80px [r2-end];
            grid-template-columns: [c1-start] 80px [c1-end c2-start] 300px [c2-end];
            color: #444953;
            padding-left: 15px;
            div:first-child {
              grid-row-start: r1-start;
              grid-row-end: r2-end;
              grid-column-start: c1-start;
              grid-column-end: c1-end;
              .address {
                width: 48px;
                height: 48px;
              }
              .contact {
                width: 59px;
                height: 48px;
              }
              .message {
                width: 48px;
                height: 32px;
              }
            }
            div:nth-child(2) {
              font-size: 24px;
              line-height: 32px;
            }
            div:nth-child(3) {
              font-size: 15px;
              opacity: 0.5;
            }
          }
        }
        .contect-img {
          width: 730px;
          height: 350px;
          object-fit: cover;
        }
      }
    }
    .slide-view {
      position: absolute;
      top: 50px;
      text-align: right;
      .contect-bgc1 {
        height: 294px;
      }
      .contect-bgc1.richmond{
        width:186px;
      }
      .title {
        font-size: 36px;
        color: #002369;
        margin-top: 10px;
      }
      .contect-line {
        width: 40%;
      }
    }
  }
	@media (min-width:800px) and (max-width: 1400px) {
		.contect-wrap{
			.contect-container {
				width:calc(100% - 150px);
				margin-left:150px;
				.item-padding {
					padding-left:0;
					margin-right:20px;
				}
				.center{
					width:60%;
				}
				.contect-item{
					.contect-img{
						width: 100%;
					}
				}
			}
			.slide-view{
				width:150px;
				.contect-bgc1{
					width:100%;
				}
				.title{
					font-size:30px;
				}
			}
		}
	}
  @media (max-width: 768px) {
    margin: 10% 0 !important;
    margin-bottom: 0 !important;
    .contect-wrap {
      border-bottom: 20px #F7F9FC solid;
      .contect-container {
        padding: 0;
        flex-wrap: wrap;
        .center {
          .map_line {
            width: 30px;
            height: 39px;
            padding-bottom: 10px;
            top: 50%;
            left: 51%;
            transform: translate(-50%);
          }
        }
        .contect-item {
          margin: auto;
          padding: 0;
          .contect-mobie-title {
            display: block;
            text-align: center;
            font-size: 18px;
            line-height: 2em;
            color: #002369;
            font-weight: 700;
            margin-bottom: 15px;
            margin-top: 10px;
            &:after {
              content: "";
              position: absolute;
              top: 16%;
              left: calc(50% - 9px);
              width: 18px;
              height: 3px;
              background-color: #002369;
            }
          }
          .contect-nav {
            height:auto;
            background-color: #fff;
            display: flex;
            flex-wrap: nowrap;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0;
            padding-top: 5%;
            padding-bottom: 20px;
            .contect-bgc {
              display: block;
              height: 192px;
              margin-right: 10px;
            }
            .address-mobie {
              display: grid;
              grid-template-rows: [r1-start] 30px [r1-end r2-start] auto [r2-end] !important;
              grid-template-columns: [c1-start] 50px [c1-end c2-start] 180px [c2-end];
            }
            .contect-list {
              display: grid;
              grid-template-rows: [r1-start] 30px [r1-end r2-start] 25px [r2-end];
              grid-template-columns: [c1-start] 50px [c1-end c2-start] 180px [c2-end];
              margin-bottom: 3%;
              div:first-child {
                /* padding-right: 10px; */
                grid-row-start: r1-start;
                grid-row-end: r2-end;
                grid-column-start: c1-start;
                grid-column-end: c1-end;
                img {
                  height: 30px;
                  margin-top: 5px !important;
                }
                .address {
                  width: 35px;
                  height: 37px;
                }
                .contact {
                  width: 37.72px;
                  height: 30.64px;
                }
                .message {
                  width: 35.27px;
                  height: 24.61px;
                }
              }
              div:nth-child(2) {
                font-size: 17px;
                line-height: 26px;
                overflow: hidden;
                max-height: 2em;
              }
              div:nth-child(3) {
                font-size: 12px;
                line-height: inherit;
                overflow: hidden;
              }
            }
          }
          .contect-img {
            width: auto;
            height: 200px;
            object-fit: cover;
          }
        }
      }
      .slide-view {
        display: none;
      }
    }
  }
  @media (max-width: 376px) {
    .contect-wrap {
      .contect-container {
        padding: 0;
        .contect-item {
          .contect-mobie-title {
            display: block;
            text-align: center;
            font-size: 18px;
            line-height: 2em;
            color: #002369;
            font-weight: 700;
            margin-bottom: 15px;
            margin-top: 5px;
            &:after {
              content: "";
              position: absolute;
              top: 16%;
              left: calc(50% - 9px);
              width: 18px;
              height: 3px;
              background-color: #002369;
            }
          }
          .contect-nav {
            background-color: #fff;
            display: flex;
            flex-wrap: nowrap;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0;
            padding-top: 5%;
            padding-bottom: 20px;
            .contect-bgc {
              display: block;
              height: 192px;
            }
            .contect-bgc.richmond{
              width:122px;
            }
            .address-mobie {
              display: grid;
              grid-template-columns: [c1-start] 50px [c1-end c2-start] 130px [c2-end];
            }
            .contect-list {
              display: grid;
              grid-template-rows: [r1-start] 25px [r1-end r2-start] 35px [r2-end];
              grid-template-columns: [c1-start] 45px [c1-end c2-start] 150px [c2-end];
              padding-left: 15px;
              div:first-child {
                grid-row-start: r1-start;
                grid-row-end: r2-end;
                grid-column-start: c1-start;
                grid-column-end: c1-end;
                img {
                  height: 30px;
                  margin-top: 10px !important;
                }
                .address {
                  /* width: 32.25px;
                  height: 35.7px; */
                }
                .contact {
                  width: 37.72px;
                  height: 30.64px;
                }
                .message {
                  width: 35.27px;
                  height: 24.61px;
                }
              }
              div:nth-child(2) {
                font-size: 17px;
                line-height: 26px;
                overflow: hidden;
                max-height: 2em;
              }
              div:nth-child(3) {
                font-size: 12px;
                line-height: inherit;
                overflow: hidden;
              }
            }
          }
          .contect-img {
            height: 185px;
            object-fit: cover;
          }
        }
        .item-padding {
          padding: 0;
        }
      }
    }
  }
`;

export default function ContectUs() {
  const { t } = useTranslation();

  return (
    <ContectUsStyle className="wrap-v1 fontstyle_SB ">
      <GTTitle
        title={t("contect_faq.contect_us")}
        position="left"
        mobieTitle={"contect_faq.contect_us"}
      />
      <div className="contect-wrap">
        <div className="contect-container">
          <div className="contect-item center">
            <div className="contect-mobie-title">
              {t("contect_faq.Richmond_frist")}
            </div>
            <img src={map1.default} alt="" className="contect-img" />
          </div>
          <div className="contect-item item-padding">
            <div className="contect-nav">
              <img src={bgc1.default} alt="" className="contect-bgc richmond" />
              <div className="contect-height">
                <div className="address-mobie contect-list ">
                  <div>
                    <img src={address.default} alt="" className="address" />
                  </div>
                  <div>{t("contect_faq.common.address.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.address.info2")}
                  </div>
                </div>
                {/* <div className="contect-list">
                  <div>
                    <img src={contact.default} alt="" className="contact" />
                  </div>
                  <div>{t("contect_faq.common.contact_info.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.contact_info.info")}
                  </div>
                </div>
                <div className="contect-list">
                  <div>
                    <img src={message.default} alt="" className="message" />
                  </div>
                  <div>{t("contect_faq.common.email.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.email.info")}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="slide-view">
          <img src={bgc1.default} alt="" className="contect-bgc1 richmond" />
          <div className="title">{t("contect_faq.Richmond_frist")}</div>
          <img src={line.default} alt="" className="contect-line" />
        </div>
      </div>
      <div className="contect-wrap">
        <div className="contect-container">
          <div className="contect-item center">
            <div className="contect-mobie-title">
              {t("contect_faq.Burnaby")}
            </div>
            <img src={map2.default} alt="" className="contect-img" />
          </div>
          <div className="contect-item item-padding">
            <div className="contect-nav">
              <img src={bgc.default} alt="" className="contect-bgc" />
              <div>
                <div className="address-mobie contect-list ">
                  <div>
                    <img src={address.default} alt="" className="address" />
                  </div>
                  <div>{t("contect_faq.common.address.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.address.info4")}
                  </div>
                </div>
                {/* <div className="contect-list">
                  <div>
                    <img src={contact.default} alt="" className="contact" />
                  </div>
                  <div>{t("contect_faq.common.contact_info.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.contact_info.info")}
                  </div>
                </div>
                <div className="contect-list">
                  <div>
                    <img src={message.default} alt="" className="message" />
                  </div>
                  <div>{t("contect_faq.common.email.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.email.info")}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="slide-view">
          <img src={bgc.default} alt="" className="contect-bgc1" />
          <div className="title">{t("contect_faq.Burnaby")}</div>
          <img src={line.default} alt="" className="contect-line" />
        </div>
      </div>
      <div className="contect-wrap">
        <div className="contect-container">
          <div className="contect-item center">
            <div className="contect-mobie-title">
              {t("contect_faq.Vancouver")}
            </div>
            <img src={map3.default} alt="" className="contect-img" />
          </div>
          <div className="contect-item item-padding">
            <div className="contect-nav">
              <img src={bgc2.default} alt="" className="contect-bgc" />
              <div>
                <div className="address-mobie contect-list ">
                  <div>
                    <img src={address.default} alt="" className="address" />
                  </div>
                  <div>{t("contect_faq.common.address.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.address.info5")}
                  </div>
                </div>
                {/* <div className="contect-list">
                  <div>
                    <img src={contact.default} alt="" className="contact" />
                  </div>
                  <div>{t("contect_faq.common.contact_info.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.contact_info.info")}
                  </div>
                </div>
                <div className="contect-list">
                  <div>
                    <img src={message.default} alt="" className="message" />
                  </div>
                  <div>{t("contect_faq.common.email.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.email.info")}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="slide-view">
          <img src={bgc2.default} alt="" className="contect-bgc1" />
          <div className="title">{t("contect_faq.Vancouver")}</div>
          <img src={line.default} alt="" className="contect-line" />
        </div>
      </div>
      <div className="contect-wrap">
        <div className="contect-container">
          <div className="contect-item center">
            <div className="contect-mobie-title">
              {t("contect_faq.Markham")}
            </div>
            <img src={map4.default} alt="" className="contect-img" />
          </div>
          <div className="contect-item item-padding">
            <div className="contect-nav">
              <img src={bgc3.default} alt="" className="contect-bgc" />
              <div>
                <div className="address-mobie contect-list ">
                  <div>
                    <img src={address.default} alt="" className="address" />
                  </div>
                  <div>{t("contect_faq.common.address.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.address.info6")}
                  </div>
                </div>
                {/* <div className="contect-list">
                  <div>
                    <img src={contact.default} alt="" className="contact" />
                  </div>
                  <div>{t("contect_faq.common.contact_info.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.contact_info.info")}
                  </div>
                </div>
                <div className="contect-list">
                  <div>
                    <img src={message.default} alt="" className="message" />
                  </div>
                  <div>{t("contect_faq.common.email.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.email.info")}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="slide-view">
          <img src={bgc3.default} alt="" className="contect-bgc1" />
          <div className="title">{t("contect_faq.Markham")}</div>
          <img src={line.default} alt="" className="contect-line" />
        </div>
      </div>
      <div className="contect-wrap">
        <div className="contect-container">
          <div className="contect-item center">
            <div className="contect-mobie-title">
              {t("contect_faq.Richmond")} {t("contect_faq.Hill")}
            </div>
            <img src={map5.default} alt="" className="contect-img" />
          </div>
          <div className="contect-item item-padding">
            <div className="contect-nav">
              <img src={bgc4.default} alt="" className="contect-bgc" />
              <div>
                <div className="address-mobie contect-list ">
                  <div>
                    <img src={address.default} alt="" className="address" />
                  </div>
                  <div>{t("contect_faq.common.address.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.address.info7")}
                  </div>
                </div>
                {/* <div className="contect-list">
                  <div>
                    <img src={contact.default} alt="" className="contact" />
                  </div>
                  <div>{t("contect_faq.common.contact_info.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.contact_info.info")}
                  </div>
                </div>
                <div className="contect-list">
                  <div>
                    <img src={message.default} alt="" className="message" />
                  </div>
                  <div>{t("contect_faq.common.email.title")}</div>
                  <div className="fontstyle_SR">
                    {t("contect_faq.common.email.info")}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="slide-view">
          <img src={bgc4.default} alt="" className="contect-bgc1" />
          <div className="title" style={{ textAlign: "right" }}>
            {t("contect_faq.Richmond")}
            <br />
            {t("contect_faq.Hill")}
          </div>
          <img src={line.default} alt="" className="contect-line" />
        </div>
      </div>
    </ContectUsStyle>
  );
}
