// 信息展开更多组件
import React, { useState } from "react";

import { Collapse, Spin, message } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import GTButton from "../../pages/GT_Button";
import GTTitle from "@/pages/GT_Title";
import GTTodo from "@/pages/GT_Todo";
import { LoadingOutlined } from "@ant-design/icons";
const redlogo = require("../../assets/img/Nextlogo@2x.png");

const { Panel } = Collapse;

const FAQStyle = styled.div`
  position: relative;
  background-color: #fff;

  /* .red-block {
    display: block;
    width: 64px;
    height: 64px;
    position: absolute;
    background-color: #f33500;
    opacity: 0.2;
    top: 30px;
    right: 20%;
  } */

  .move-red-button {
    display: none;
  }
  .ss-container {
    .right_button {
      display: block;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5%;

      .title {
        font-size: 42px;
        line-height: 57px;
        font-weight: 700;
      }
    }

    .collapse-item {
      display: flex;
      justify-content: space-between;

      .mobie-show {
        display: none !important;
      }
      .space {
        padding-left: 5%;
        display: block;
      }
      .faq-collapse {
        display: block;
        flex-wrap: wrap;
        margin: 3% 0;
        flex: 1;
        width:50%;
        .ant-collapse-header {
          height: 80px;
          font-size:${window.localStorage.getItem('i18nextLng') === 'en'?'16px':'20px'};
          // line-height: 2.5em;
          font-family: SossSPR;
          background-color: #f7f9fc;
          margin-bottom: 20px;
          padding-left: 5%;
          display:flex;
          align-items:center;
          &:hover {
            background-color: #fdd6cc;
            /* color: #f33500 !important; */
          }
        }

        .ant-collapse-item-active {
          position: relative;
          .ant-collapse-header {
            color: red !important;
          }
        }

        .ant-collapse-item {
          width:100%;
          .ant-collapse-content {
            .ant-collapse-content-box {
              width: 80%;
              font-size: 18px;
              line-height: 2.1em;
              height: 140px;
              overflow-y: scroll;
              margin: 30px;
              padding: 0 0 0 50px;
              background-color: white;
              &::-webkit-scrollbar{/*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
              		width:4px;
              		height:4px;
              }
              &::-webkit-scrollbar-button{/*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*/
              		display:none;
              }
              &::-webkit-scrollbar-track{/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
              		background:rgb(241,241,241);
              }
              &::-webkit-scrollbar-track-piece{/*内层轨道，滚动条中间部分（位置4）*/
              		background:rgb(241,241,241);
              }
              &::-webkit-scrollbar-thumb{/*滚动条里面可以拖动的那部分（位置5）*/
              	background:rgb(193,193,193);
              }
              &::-webkit-scrollbar-corner {/*边角（位置6）*/
              	display:none;
              }
              &::-webkit-scrollbar-resizer  {/*定义右下角拖动块的样式（位置7）*/
              	bdisplay:none;
              }
              // &::-webkit-scrollbar {
              //   display: none;
              // }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {


    .move-red-button {
      display: block;
      button{
        width: 100%;
        padding:14px 18px;
        background-color: #f33500;
        border-radius: 7px;

        span{
          font-size:14px;
          height: 19px;
          line-height: 19px;
          padding-right: 6%;
          color: #fff;
        }

        .button-imgs{
          height: 26px;
        }
      }
    }

    .ss-container {
      padding: 0;
      margin-bottom: 3%;
      .right_button {
        display: none;
      }
      .collapse-item {
        display: flex;
        justify-content: space-between;
        .mobie-show {
          display: block !important;
        }
        .space {
          display: none;
        }
        .faq-collapse {
          display: none;
          flex-wrap: wrap;
          margin: 4% 0;
          flex: 1;

          .ant-collapse-header {
            height: 80px;
            font-size: ${window.localStorage.getItem('i18nextLng') === 'en'?'12px':'16px'};
            background-color: #f7f9fc;
            margin: 0 4%;
            &:hover {
              background-color: #f7f9fc;
            }
          }

          .ant-collapse-item {
            margin: 2% 0;
            .ant-collapse-content {
              .ant-collapse-content-box {
                width: 80%;
                font-size: 14px;
                max-height: 88px;
                line-height: 2em;
                padding: 0;
                color: #7d7d7d;
                padding-left: 5%;
                margin: 7%;
                background-color: white;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 320px) {


    .ss-container {
      padding: 0;
      .right_button {
        display: none;
      }
      .collapse-item {
        display: flex;
        justify-content: space-between;
        .mobie-show {
          display: block !important;
        }
        .space {
          display: none;
        }
        .faq-collapse {
          display: none;
          flex-wrap: wrap;
          margin: 0;
          flex: 1;

          .ant-collapse-header {
            height: 60px;
            font-size: 14px;
            background-color: #f7f9fc;
            margin: 0 6%;

            &:hover {
              background-color: #f7f9fc;
            }
          }

          .ant-collapse-item {
            .ant-collapse-content {
              .ant-collapse-content-box {
                width: 80%;
                font-size: 14px;
                max-height: 88px;
                line-height: 2em;
                padding: 0;
                color: #7d7d7d;
                padding-left: 20px;
                margin: 10px 30px;
                background-color: white;
              }
            }
          }
        }
      }
    }
  }
`;

export default function GTFAQ() {
  const lng = window.localStorage.getItem('i18nextLng');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [hasmore, sethasMore] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <FAQStyle className="fix-margin-top">
      <GTTitle
        title={t("contect_faq.title")}
        position="left"
        mobieTitle="contect_faq.title"
      />
      {/* <div className="red-block"></div> */}
      <div className="ss-container wrap-v1">
        {
          <div className="collapse-item">
            <Collapse
              destroyInactivePanel
              expandIconPosition={"right"}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusOutlined style={{ color: "#F33500", fontSize: 25 }} />
                ) : (
                  <PlusOutlined style={{ fontSize: 25 }} />
                )
              }
              accordion={true}
              bordered={false}
              // defaultActiveKey={["0"]}
              className="faq-collapse"
              ghost
            >
              <Panel
                forceRender={true}
                key="1"
                header={'01.' + t("faq.title1")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc1")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="3"
                header={'03.' + t("faq.title3")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc3")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="5"
                header={'05.' + t("faq.title5")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc5")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="7"
                header={'07.' + t("faq.title7")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc7")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="9"
                header={'09.' + t("faq.title9")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc9")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="11"
                header={'11.' + t("faq.title11")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc11")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="13"
                header={'13.' + t("faq.title13")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc13")}}></div>
              </Panel>
            </Collapse>
            <Collapse
              destroyInactivePanel
              expandIconPosition={"right"}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusOutlined style={{ color: "#F33500", fontSize: 25 }} />
                ) : (
                  <PlusOutlined style={{ fontSize: 25 }} />
                )
              }
              accordion={true}
              bordered={false}
              className="faq-collapse space"
              ghost
            >
              <Panel
                forceRender={true}
                key="2"
                header={'02.' + t("faq.title2")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc2")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="4"
                header={'04.' + t("faq.title4")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc4")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="6"
                header={'06.' + t("faq.title6")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc6")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="8"
                header={'08.' + t("faq.title8")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc8")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="10"
                header={'10.' + t("faq.title10")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc10")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="12"
                header={'12.' + t("faq.title12")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc12")}}></div>
              </Panel>
            </Collapse>
            <Collapse
              destroyInactivePanel
              expandIconPosition={"right"}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusOutlined style={{ color: "#F33500", fontSize: 25 }} />
                ) : (
                  <PlusOutlined style={{ fontSize: 25 }} />
                )
              }
              accordion={true}
              bordered={false}
              // defaultActiveKey={["0"]}
              className="faq-collapse mobie-show"
              ghost
            >
              {
                ["01", "02", "03", "04", "05","06", "07", "08", "09", "10","11", "12", "13",].map((item, index) => {
                  return (
                    <Panel
                      forceRender={true}
                      key={index}
                      header={`${index+1}.` + t(`faq.title${index+1}`)}
                    >
                      <div dangerouslySetInnerHTML={{__html: t(`faq.desc${index+1}`)}}></div>
                    </Panel>
                  );
                })
              }
            </Collapse>
          </div>
        }
        <Spin spinning={loading} indicator={antIcon}></Spin>
        {/* 移动端按钮 */}
        {/* hasmore ? (
          <div className="move-red-button" style={{ margin: "0 4% 10%" }}>
            <button
              onClick={(e) => {
                sethasMore(false);
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                  message.error(t("faq.hasmore"));
                }, 1500);
              }}
              // title={t("home.join_props_title")}
              // mobieTitle="routes-media.watch"
            >
              <span>{t("routes-media.loading")}</span>
              <img src={redlogo.default} alt="" className="button-imgs" />
            </button>
          </div>
        ) : null */}

        {/* hasmore ? (
          <div className="red-button">
            <GTButton
              poscenter="center"
              bgcColor={"#F33500"}
              changeLink={(e) => {
                sethasMore(false);
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                  message.error(t("faq.hasmore"));
                }, 1500);
              }}
            />
          </div>
        ) : null */}
      </div>
      <GTTodo />
    </FAQStyle>
  );
}
