
import React, { memo, useState, useEffect } from "react";

import { renderRoutes } from "react-router-config";
import { withRouter } from "react-router";

import routes from "@/routes";
import GTHeader from '@/pages/GT_Header'
import GTFooter from '@/pages/GT_Footer'
import GTMobieHeader from './pages/GT_Mobie_header'
import { useTranslation } from "react-i18next";

function App(props) {
  const { location } = props;
  const [currentWidth, setCurrentWidth] = useState(0)
  const pathname = location.pathname;
  const { t } = useTranslation();
  useEffect(() => {
    // 进行浏览器刷新的功能
    setCurrentWidth(document.body.clientWidth)
   
  }, [setCurrentWidth])
  // 根据路由匹配国际化标题 
  const matchRouter = (routes) => {
    for (let i in routes) {
      if (pathname === routes[i].path) {
        if(pathname  === "/video-frist" ){
            return location.state.info.title
        }else{
          return routes[i].name
        }
      }
    }

  }

  const showPage = (routes) => {
    if (currentWidth <= '768') {
      if (pathname === '/home' || pathname === '/') {
        return (
          <>
            <GTHeader />
            {renderRoutes(routes)}
            < GTFooter />
          </>
        )
      } else {
        return (
          <>
            <GTMobieHeader title={t(matchRouter(routes))}  />
            {renderRoutes(routes)}
            {
              // 定义另外组件
              pathname === '/video-detail'||pathname === "/reward-detail" || pathname === "/video-frist" ? null : <GTFooter />
            }
          </>
        )
      }
    } else {
      return (
        <>
          <GTHeader  />
          {renderRoutes(routes)}
          < GTFooter />
        </>
      )
    }
  }
  return (
    <>
      {showPage(routes)}
    </>
  )
}


export default withRouter(memo(App));