import React, { useEffect, useState } from "react";
import { Breadcrumb, Popover } from "antd";
import styled from "styled-components";
import * as dayjs from "dayjs";
import { getNews } from "@/service/video";
import { ImageURL } from "@/service/config";
import RewardHeader from "../RewardHeader/detail.jsx";
import SpecialistVideo from "@/pages/GT_Specialtiesvideo/";
import { useTranslation } from "react-i18next";
const date = require("@/assets/img/Reward_tabs_date@2x.png");
const LeftButtonIcon = require("../../../assets/img/RewardDetailLeftButtonIcon@2x.png");

const RightFacebookIcon = require("../../../assets/img/RewardIconFacebook@2x.png");
const RightInstagramIcon = require("../../../assets/img/wx.svg");
const RightTwitterIcon = require("../../../assets/img/youtube.svg");
const circle_wechart = require("@/assets/img/GTFooter_circle_wechart@2x.png");
const wechat = require("@/assets/img/wechat.png");

const RewardDetaliRightImageLine = require("../../../assets/img/RewardDetaliRightImageLine@2x.png");
const Image = function (pic) {
  return ImageURL + pic;
};
const wxPic = <img src={wechat.default} alt="" style={{ width: "200px" }} />;
export default function Reward_PromotionDetail(props) {
  const { title, content, updatedAt, desc } = props.location.state;
  const [newsAll, setNewAll] = useState([]);
  const { t } = useTranslation();
  const ispc = document.body.clientWidth >= 768 ? true : false;
  useEffect(() => {
    getNews(1, "", 3).then((res) => {
      setNewAll(res.data);
    });
  });
  function createMarkup() {
    return { __html: content };
  }

  return (
    <RewardDetailWrapper className="fix-margin-top">
      {/* 导航面包屑 */}
      <RewardHeader />
      <div className="nav sprite_04">
        <div className="wrap-v1">
          <Breadcrumb
            separator={
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  border: "1px solid red",
                  display: "inline-block",
                }}
              ></div>
            }
          >
            <Breadcrumb.Item href="/home" className="mian">
              {t("routes.Home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/reward-promotion">
              {t("routes.Reward_Promotion")}
            </Breadcrumb.Item>
            <Breadcrumb.Item className="active">
              {t("routes.Reward_Detail")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="wrap-v1">
        <RewardDetailContainerWrapper>
          <div className="RewardDetailLeft">
            <div className="RewardDetailTitle">
              <p>{title}</p>
            </div>

            <div className="RewardDetailInfo">
              <img src={date.default} alt="" className="RewardDetailDateIcon" />
              <div className="RewardDetailDateNum">
                {dayjs(updatedAt).locale("zh-CN").format("MM-DD-YYYY")}
              </div>
            </div>
            {desc ? (
              <div
                style={{
                  padding: "2%",
                  margin: "0 0 3% 0",
                  border: "1px solid #DADCDF",
                  fontSize: "16px",
                  color: "#999",
                }}
              >
                {desc}
              </div>
            ) : null}
            <div
              className="danger"
              dangerouslySetInnerHTML={createMarkup()}
            ></div>
            <div className="RewardDetailButton">
              <button
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <img
                  src={LeftButtonIcon.default}
                  alt=""
                  className="LeftButtonIcon"
                />
                <p>{t("reward.back")}</p>
              </button>
            </div>
          </div>

          <div className="RewardDetailRight">
            <div className="RewardDetailTitle">{t("reward.now")}</div>
            <div className="RewardDetailSmallTitle">
              {t("reward.vip_activity")}
            </div>
            <div className="RewardDetailEmjoyIconGroup">
              <div
                className="RightGroupItem"
                onClick={(e) => {
                  window.open("https://www.facebook.com/GTWealthCanada/");
                }}
              >
                <img
                  src={RightFacebookIcon.default}
                  alt=""
                  className="RightFacebookIcon"
                />
              </div>
              <div
                className="RightGroupItem"
                onClick={(e) => {
                  window.open("https://www.youtube.com/user/GrandtagGroup");
                }}
              >
                <img
                  src={RightTwitterIcon.default}
                  alt=""
                  className="RightTwitterIcon"
                />
              </div>
              <div className="RightGroupItem">
                <Popover
                  content={wxPic}
                  title={t("topbanner.wechat_connect")}
                  placement="top"
                  overlayInnerStyle={{
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src={RightInstagramIcon.default}
                    alt=""
                    className="RightInstagramIcon"
                  />
                </Popover>
              </div>
            </div>

            <div className="RewardDetailNewsItem">
              <div className="RewardDetailNewsTitle">
                {t("reward.newstitle")}
              </div>
              <div className="RewardDetailNewsList cursor">
                {newsAll
                  ? newsAll.slice(0, 5).map((item, index) => {
                      return (
                        <div
                          className="RewardDetailNewsListContent"
                          key={index}
                          onClick={() => {
                            props.history.push("/reward-detail", {
                              id: item.id,
                              title: item.title,
                              updatedAt: item.updatedAt,
                              content: item.content,
                              desc: item.digest,
                            });
                          }}
                        >
                          <div className="RewardDetailNewsListImage">
                            <img
                              src={RewardDetaliRightImageLine.default}
                              alt=""
                              className="RewardDetaliRightImageLine"
                            />
                            <img
                              src={Image(item.pic)}
                              alt=""
                              className="RewardDetailRightImage"
                            />
                          </div>
                          <div className="RewardDetailNewsListInfo">
                            <div className="RewardDetailNewsListInfoTitle">
                              {item.title}
                            </div>
                            <div className="RewardDetailNewsListInfoDate">
                              <img
                                src={date.default}
                                alt=""
                                className="RewardDetailDate"
                              />
                              <p>
                                {dayjs(item.updatedAt)
                                  .locale("zh-CN")
                                  .format("MM-DD-YYYY")}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}

                {/* 复制 */}
              </div>
            </div>
          </div>
        </RewardDetailContainerWrapper>
      </div>

      {ispc ? (
        <div className="specialties-container">
          <SpecialistVideo title={t("special.video-title")} />
        </div>
      ) : null}
    </RewardDetailWrapper>
  );
}

const RewardDetailWrapper = styled.div`
  .nav {
    height: 53px;
    display: flex;
    align-items: center;
    margin: 35px 0;

    .ant-breadcrumb-separator {
      margin: 0 20px;
    }
    .active {
      color: #f33500;
    }
  }
  .RewardDetailButtonGroup {
    display: none;
  }

  @media (max-width: 768px) {
    .nav {
      display: none;
    }

    .RewardDetailButtonGroup {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 22px 4%;
      width: 100%;
      background-color: #f7f9fc;
      border-radius: 4px;

      button {
        width: 47%;
        height: 50px;
      }
    }
  }
`;

const RewardDetailContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 3% auto;
  .RewardDetailLeft {
    flex: 3;
    display: flex;
    flex-direction: column;

    .RewardDetailTitle {
      font-size: 36px;
      color: black;
      line-height: 52px;
    }
    .RewardDetailInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #8b8b8b;
      font-size: 16px;
      line-height: 29px;
      width: 11.5%;
      margin: 2% 0;
      .RewardDetailDateIcon {
        width: 16px;
      }
      .RewardDetailDateNum {
      }

      .RewardDetailLine {
        margin: 0 5% 0 0;
        opacity: 0.4;
      }
      .RewardDetaillookIcon {
        width: 15px;
      }
      .RewardDetailLookNum {
      }
      .Active {
        color: #f33500;
      }
    }
    .danger {
      p {
        font-size: 18px !important;
        line-height: 1.55em;
      }
    }
    .RewardDetailBgc {
      width: 960px;

      .RewardDetailDescBgc {
        width: 100%;
      }
    }

    .RewardDetailDesc {
      font-size: 16px;
      line-height: 28px;
      margin: 2% 0;
      p {
        margin-bottom: 1em;
      }
    }
    .RewardDetailMore {
      .RewardDetailMoreTitle {
        font-size: 36px;
        line-height: 52px;
        color: black;
        font-weight: 400;

        span {
          margin-right: 4%;
        }
      }

      .RewardDetailMoreDesc {
        font-size: 16px;
        line-height: 28px;
        p {
          margin-top: 0.5em;
          margin-bottom: 1em;
        }
      }
    }

    .RewardDetailButton {
      width: 260px;
      height: 60px;
      margin: 4% 0;
      button {
        background-color: #f33500;
        border: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 0 10%;

        .LeftButtonIcon {
          width: 33px;
          height: 8px;
        }
        p {
          font-size: 16px;
          line-height: 21px;
          color: #fff;
        }
      }
    }
  }
  .RewardDetailRight {
    flex: 1;
    margin-left: 10%;
    color: #000000;
    .RewardDetailTitle {
      font-size: 20px;
      line-height: 52px;
    }
    .RewardDetailSmallTitle {
      color: #444953;
      font-size: 14px;
    }

    .RewardDetailEmjoyIconGroup {
      display: flex;
      align-items: center;
      margin: 2% 0;
      .Active {
        background-color: #f33500;
      }
      .RightGroupItem {
        height: 48px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3%;
        cursor: pointer;
        .RightFacebookIcon {
          width: 29px;
          height: 29px;
        }

        .RightTwitterIcon {
          width: 31px;
          height: 25px;
        }
        .RightInstagramIcon {
          width: 26px;
          height: 26px;
        }
        .RightGoogleIcon {
          width: 24px;
          height: 25px;
        }
      }
    }

    .RewardDetailNewsItem {
      .RewardDetailNewsTitle {
        font-size: 20px;
        line-height: 52px;
      }
      .RewardDetailNewsList {
        .RewardDetailNewsListContent {
          margin-top: 8%;
          display: flex;
          .RewardDetailNewsListImage {
            height: 78px;
            display: flex;
            .RewardDetaliRightImageLine {
              width: 4px;
            }
            .RewardDetailRightImage {
              width: 117px;
              height: 100%;
            }
          }
          .RewardDetailNewsListInfo {
            flex-basis: 450px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 0 3% 5%;
            .RewardDetailNewsListInfoTitle {
              width: 100%;
              height: 42px;
              overflow: hidden;
              font-size: 15px;
              line-height: 20px;
              color: #000000;
            }
            .RewardDetailNewsListInfoDate {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-top: 2%;
              width: 54%;
              .RewardDetailDate {
                width: 12px;
                height: 12px;
              }
              p {
                padding-left: 2px;
                color: #8b8b8b;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .RewardDetailLeft {
      .RewardDetailInfo {
        width: auto;
        justify-content: flex-start;
        .RewardDetailDateIcon {
          margin-right: 10px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 3%;
    .RewardDetailLeft {
      margin-bottom: 10%;
      .RewardDetailTitle {
        font-size: 20px;
        line-height: 22px;
        /* padding: 2% 0; */
        padding-top: 3%;
      }
      .RewardDetailInfo {
        justify-content: flex-start;
        height: 30px;
        margin: 2%;
        width: 30%;
        font-size: 12px;
        line-height: 22px;
        .RewardDetailLine {
          display: none;
        }
        .RewardDetailDateNum {
          margin-left: 5%;
        }
      }
      .danger {
        p {
          font-size: 16px !important;
        }
        img {
          width: 100%;
        }
      }
      .RewardDetailBgc {
        width: auto !important;
      }
      .RewardDetailDesc {
        p {
          font-size: 14px;
          line-height: 22px;
          color: #000000;
        }
      }

      .RewardDetailMore {
        .RewardDetailMoreTitle {
          font-size: 20px;
          line-height: 52px;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          color: #000000;
        }
      }

      .RewardDetailButton {
        display: none;
      }
    }

    .RewardDetailRight {
      display: none;
    }
  }
`;
